import React, { useState } from "react";
import Layout from "../../components/layout/Layout";
import Admin from "../../components/auth/Admin";
import {
  AppBar,
  makeStyles,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { Box } from "@mui/system";
import ManageCompanyRequest from "./ManageCompanyRequest";
import ManageUpdateCompanyrequest from "./ManageUpdateCompanyRequest";
import NewsRequests from "./NewsRequests";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    padding: theme.spacing(3),
    position: "relative",
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "75%",
  },
  Select: {
    position: "absolute",
    right: "10px",
    width: "150px",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} paddingLeft={0} paddingRight={0}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Managerequests = () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Layout>
      <Admin>
        <Paper className={classes.pageContent}>
          <div>
            <div className={classes.root}>
              <AppBar position="static" color="default">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                >
                  <Tab label="Add company" {...a11yProps(0)} />
                  <Tab label="Update company" {...a11yProps(1)} />
                  <Tab label="Add news" {...a11yProps(2)} />
                </Tabs>
              </AppBar>
            </div>

            <TabPanel value={value} index={0}>
              <ManageCompanyRequest />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <ManageUpdateCompanyrequest />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <NewsRequests />
            </TabPanel>
          </div>
        </Paper>
      </Admin>
    </Layout>
  );
};

export default Managerequests;
