import React from "react";
import { CssBaseline, ThemeProvider, createTheme } from "@material-ui/core";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Companies from "./pages/admin/Companies";
import Callcenter from "./pages/admin/Callcenter";
import StaffSignin from "./pages/Signin";
import Catagories from "./pages/admin/Catagories";
import Index from "./pages/callcenter/CallCenter";
import CompanyList from "./components/CompanyList";
import SubCatagoryComponent from "./components/SubCatagoryComponent";
import SearchCompany from "./pages/callcenter/SearchCompany";
import CompanyDetail from "./pages/admin/CompanyDetail";
import CompanyDetailcallcenter from "./pages/callcenter/CompanyDetailcallcenter";
import ManageNews from "./pages/admin/News";
import Users from "./pages/admin/Users";
import { isAuth } from "./actions/auth";
import Ads from "./pages/admin/Ads";
import Dashboard from "./pages/admin/Dashboard";
import Managerequests from "./pages/admin/Managerequests";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgba(244,151,3,.8)",
      light: "rgba(183, 42, 56, 0.788)",
      contrastText: "#fff",
    },
    secondary: {
      main: "rgb(20,61,89)",
      light: "#f8324526",
      contrastText: "#fff",
    },
    background: {
      default: "#f4f5fd",
    },
  },
  overrides: {
    MuiAppBar: {
      root: {
        shadow: "0",
      },
    },
  },
  props: {
    MuiIconButton: {
      disableRipple: true,
    },
  },
});

const App = () => {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <Switch>
          <Route
            path="/admin"
            exact
            render={(props) => {
              return isAuth() ? (
                <Dashboard {...props} />
              ) : (
                <StaffSignin {...props} />
              );
            }}
          />
          <Route
            exact
            path="/admin/company"
            render={(props) => {
              return isAuth() ? (
                <Companies {...props} />
              ) : (
                <StaffSignin {...props} />
              );
            }}
          />
          <Route path="/admin/callcenter" component={Callcenter} />
          <Route path="/admin/catagories" component={Catagories} />
          <Route path="/staff/signin" component={StaffSignin} />
          <Route path="/callcenter" component={Index} />
          <Route path="/companylist/:catagoryId" component={CompanyList} />
          <Route
            path="/subcatagory/:catagoryId"
            component={SubCatagoryComponent}
          />
          <Route
            exact
            path="/company/:Id"
            component={CompanyDetailcallcenter}
          />
          <Route
            exact
            path="/"
            render={(props) => {
              return isAuth() ? (
                <SearchCompany {...props} />
              ) : (
                <StaffSignin {...props} />
              );
            }}
          />
          <Route path="/admin/company/:Id" component={CompanyDetail} />
          <Route path="/admin/news" component={ManageNews} />
          <Route path="/admin/users" component={Users} />
          <Route path="/admin/ads" component={Ads} />
          <Route path="/admin/requests" component={Managerequests} />
        </Switch>
        <CssBaseline />
      </ThemeProvider>
    </Router>
  );
};

export default App;
