import fetch from "isomorphic-fetch";
import { useHistory } from "react-router-dom";
import { API } from "../config";

import { getCookie, userSessionExpired } from "./auth";
const AdsActions = () => {
  const history = useHistory();
  const viewAllAds = () => {
    return fetch(`${API}/get-all-ads`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .catch((err) => err);
  };
  const deleteAd = (Id) => {
    return fetch(`${API}/delete-ad/${Id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("token")}`,
      },
    })
      .then((response) => {
        userSessionExpired(response, history);
        return response.json();
      })
      .catch((err) => err);
  };
 
  const addAd = (ad) => {
    return fetch(`${API}/add-ad`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getCookie("token")}`,
      },
      body: ad,
    })
      .then((response) => {
        userSessionExpired(response, history);
        return response.json();
      })
      .catch((err) => err);
  };
  return {
    viewAllAds,
    deleteAd,
    addAd,
  };
};
export default AdsActions;