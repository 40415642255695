import fetch from "isomorphic-fetch";
import { useHistory } from "react-router-dom";
import { API } from "../config";

import { getCookie, userSessionExpired } from "./auth";
const Company = () => {
  const history = useHistory();
  const addCompany = (company) => {
    return fetch(`${API}/add-company`, {
      method: "POST",
      headers: {
        Accept: "application/json",

        Authorization: `Bearer ${getCookie("token")}`,
      },
      body: company,
    })
      .then((response) => {
        userSessionExpired(response, history);
        return response.json();
      })
      .catch((err) => err);
  };
  const addCompanyFromFile = (company) => {
    return fetch(`${API}/add-company-from-file`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("token")}`,
      },
      body: JSON.stringify(company),
    })
      .then((response) => {
        userSessionExpired(response, history);
        return response.json();
      })
      .catch((err) => err);
  };
  const addCompanyForcatagory = (company, Id) => {
    return fetch(`${API}//add-company-for-catagory/${Id}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("token")}`,
      },
      body: JSON.stringify(company),
    })
      .then((response) => {
        userSessionExpired(response, history);
        return response.json();
      })
      .catch((err) => err);
  };
  const updateCompany = (company, Id) => {
    return fetch(`${API}/update-company/${Id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",

        Authorization: `Bearer ${getCookie("token")}`,
      },
      body: company,
    })
      .then((response) => {
        userSessionExpired(response, history);
        return response.json();
      })
      .catch((err) => err);
  };
  const adminUpdateUserCompany = (company, Id) => {
    return fetch(`${API}/update-requested-company/${Id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",

        Authorization: `Bearer ${getCookie("token")}`,
      },
      body: company,
    })
      .then((response) => {
        userSessionExpired(response, history);
        return response.json();
      })
      .catch((err) => err);
  };
  const deleteCompany = (Id) => {
    return fetch(`${API}/delete-company/${Id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("token")}`,
      },
    })
      .then((response) => {
        userSessionExpired(response, history);
        return response.json();
      })
      .catch((err) => err);
  };
  const deleteCompanyUpdateRequest = (Id) => {
    return fetch(`${API}/deleted-update-requested-companies/${Id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("token")}`,
      },
    })
      .then((response) => {
        userSessionExpired(response, history);
        return response.json();
      })
      .catch((err) => err);
  };
  const searchCompany = (name) => {
    return fetch(`${API}/search-company/${name}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("token")}`,
      },
    })
      .then((response) => {
        userSessionExpired(response, history);
        return response.json();
      })
      .catch((err) => err);
  };
  const companyDetails = (Id) => {
    return fetch(`${API}/company-details/${Id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("token")}`,
      },
    })
      .then((response) => {
        userSessionExpired(response, history);
        return response.json();
      })
      .catch((err) => err);
  };
  const viewAllCompanies = () => {
    return fetch(`${API}/view-all-company`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("token")}`,
      },
    })
      .then((response) => {
        userSessionExpired(response, history);
        return response.json();
      })
      .catch((err) => err);
  };
  const addSearchHistory = (data) => {
    return fetch(`${API}/add-search-history`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("token")}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        userSessionExpired(response, history);
        return response.json();
      })
      .catch((err) => err);
  };
  const viewSearchHistory = (Id) => {
    return fetch(`${API}/view-recent-search-history/${Id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("token")}`,
      },
    })
      .then((response) => {
        userSessionExpired(response, history);
        return response.json();
      })
      .catch((err) => err);
  };
  const viewRequestedCompanies = () => {
    return fetch(`${API}/get-all-requested-companies`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .catch((err) => err);
  };
  const viewUpdateRequestedCompanies = () => {
    return fetch(`${API}/get-all-updated-requested-companies`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .catch((err) => err);
  };
  const approveCompany = (Id) => {
    return fetch(`${API}/approve-requested-company/${Id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("token")}`,
      },
    })
      .then((response) => {
        userSessionExpired(response, history);
        return response.json();
      })
      .catch((err) => err);
  };
  const approveCompanyUpdateRequest = (Id) => {
    return fetch(`${API}/approve-update-requested-company/${Id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("token")}`,
      },
    })
      .then((response) => {
        userSessionExpired(response, history);
        return response.json();
      })
      .catch((err) => err);
  };
  const totalCompany = () => {
    return fetch(`${API}/total-company`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .catch((err) => err);
  };
  return {
    approveCompany,
    viewRequestedCompanies,
    addCompany,
    updateCompany,
    searchCompany,
    deleteCompany,
    viewAllCompanies,
    companyDetails,
    addSearchHistory,
    viewSearchHistory,
    addCompanyFromFile,
    addCompanyForcatagory,
    viewUpdateRequestedCompanies,
    deleteCompanyUpdateRequest,
    approveCompanyUpdateRequest,
    adminUpdateUserCompany,
    totalCompany,
  };
};
export default Company;
