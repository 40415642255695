import {
  Button,
  Grid,
  MenuItem,
  Popover,
  Select,
  Typography,
} from "@material-ui/core";

import { useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { GrAddCircle } from "react-icons/gr";
import { IconContext } from "react-icons/lib";
import { MdOutlineDelete } from "react-icons/md";
import ContactList from "../actions/ContactList";
import Controls from "./controls/Controls";
import ConfirmDialog from "./customHelpers/ConfirmDialog";
import Popup from "./customHelpers/Popup";
import { useForm } from "./customHelpers/useForm";
import AddNewContactForm from "./forms/AddNewContactForm";

const initialFValues = {
  fax: "",
  office_no: "",
  phone_no: "",
  social_media: "",
  method: "",
  object: {},
  editing: false,
};
const field_list = [
  { Id: "fax", label: "Fax" },
  { Id: "phone_no", label: "Phone No" },
  { Id: "office_no", label: "Office No" },
  { Id: "social_media", label: "Social media" },
];

const ContactMethods = ({
  NotifyMessage,
  Id,
  OfficeNumber,
  SocialMedia,
  Faxes,
  PhoneNumber,
}) => {
  const { viewContactmethods, deleteContactMethodRecord } = ContactList();

  const [recordForEdit, setRecordForEdit] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [open, setOpen] = useState(false);
  const [Field, setField] = useState({});
  const [value, setValue] = useState({
    loading: false,
    socialMedias: [],
    faxes: [],
    phoneNumbers: [],
    officeNumbers: [],
    reload: false,
  });
  useEffect(() => {
    if (recordForEdit != null)
      setValues({
        ...recordForEdit,
      });
  }, [recordForEdit]);
  const { officeNumbers, socialMedias, phoneNumbers, faxes, reload } = value;
  const [anchorEl, setAnchorEl] = useState(null);

  const popover = Boolean(anchorEl);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const id = popover ? "simple-popover" : undefined;
  useEffect(() => {

    setValues({
      ...values,
      loading: true,
    });
    setValue({
      ...values,
      socialMedias: SocialMedia.sort((a, b) =>
        a.createdAt < b.createdAt ? 1 : b.createdAt < a.createdAt ? -1 : 0
      ),
      phoneNumbers: PhoneNumber.sort((a, b) =>
        a.createdAt < b.createdAt ? 1 : b.createdAt < a.createdAt ? -1 : 0
      ),
      officeNumbers: OfficeNumber.sort((a, b) =>
        a.createdAt < b.createdAt ? 1 : b.createdAt < a.createdAt ? -1 : 0
      ),
      faxes: Faxes.sort((a, b) =>
        a.createdAt < b.createdAt ? 1 : b.createdAt < a.createdAt ? -1 : 0
      ),
      loading: false,
    });
  }, [reload]);
  const getAllContactmethods = () => {
    setValues({
      ...values,
      loading: true,
    });
    viewContactmethods(Id).then((data) => {
      if (data.err) {
        NotifyMessage({
          message: data.err,
          type: "error",
        });
      } else {
        setValue({
          ...values,
          socialMedias: data.socialMedia.sort((a, b) =>
            a.createdAt < b.createdAt ? 1 : b.createdAt < a.createdAt ? -1 : 0
          ),
          phoneNumbers: data.PhoneNumber.sort((a, b) =>
            a.createdAt < b.createdAt ? 1 : b.createdAt < a.createdAt ? -1 : 0
          ),
          officeNumbers: data.OfficeNumber.sort((a, b) =>
            a.createdAt < b.createdAt ? 1 : b.createdAt < a.createdAt ? -1 : 0
          ),
          faxes: data.Fax.sort((a, b) =>
            a.createdAt < b.createdAt ? 1 : b.createdAt < a.createdAt ? -1 : 0
          ),
          loading: false,
        });
      }
    });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleChange = (event) => {
    setErrors({});

    setValues({ ...initialFValues, method: event.target.value.split("-")[0] });
    setField({
      name: event.target.value.split("-")[0],
      label: event.target.value.split("-")[1],
    });
  };
  const openInPopup = (item, contact) => {
    const r = field_list.find((f) => f.Id == contact);
    setField({});
    setRecordForEdit({
      ...item,
      editing: true,
      object: { name: r.Id, label: r.label },
      method: r.Id,
    });
    setOpenPopup(true);
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if (Field.name == "social_media" && "social_media" in fieldValues)
      temp.social_media =
        fieldValues.social_media.length != 0 ? "" : "This field is required.";

    if (Field.name == "fax" && "fax" in fieldValues)
      temp.fax = fieldValues.fax.length != 0 ? "" : "This field is required.";

    if (Field.name == "phone_no" && "phone_no" in fieldValues)
      temp.phone_no =
        fieldValues.phone_no.length != 0 ? "" : "This field is required.";

    if (Field.name == "office_no" && "office_no" in fieldValues)
      temp.office_no =
        fieldValues.office_no.length != 0 ? "" : "This field is required.";

    setErrors({
      ...temp,
    });
    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);
  const showPhoneNumber = () => {
    return (
      <>
        <label variant="h6" className="page-header">
          Phone Number
        </label>
        <div className="row">
          {phoneNumbers.map((item, index) => (
            <div className="col-6" key={index}>
              <div className="service-card">
                <Typography variant="h6">{item.phone_no}</Typography>
                <div>
                  <Controls.ActionButton
                    color="primary"
                    title="Update"
                    onClick={() => {
                      openInPopup(item, "phone_no");
                    }}
                  >
                    <FiEdit fontSize="small" />
                  </Controls.ActionButton>
                  <Controls.ActionButton
                    color="secondary"
                    title="Delete"
                    onClick={() => {
                      setConfirmDialog({
                        isOpen: true,
                        title: "Are you sure to delete this service?",
                        subTitle: "You can't undo this operation",
                        onConfirm: () => {
                          onDelete(item.Id, "phone_no");
                        },
                      });
                    }}
                  >
                    <MdOutlineDelete fontSize="small" />
                  </Controls.ActionButton>
                </div>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  };
  const showOfficeNumber = () => {
    return (
      <>
        <label variant="h6" className="page-header">
          Office Number
        </label>
        <div className="row">
          {officeNumbers.map((item, index) => (
            <div className="col-6" key={index}>
              <div className="service-card">
                <Typography variant="h6">{item.office_no}</Typography>
                <div>
                  <Controls.ActionButton
                    color="primary"
                    title="Update"
                    onClick={() => {
                      openInPopup(item, "office_no");
                    }}
                  >
                    <FiEdit fontSize="small" />
                  </Controls.ActionButton>
                  <Controls.ActionButton
                    color="secondary"
                    title="Delete"
                    onClick={() => {
                      setConfirmDialog({
                        isOpen: true,
                        title: "Are you sure to delete this service?",
                        subTitle: "You can't undo this operation",
                        onConfirm: () => {
                          onDelete(item.Id, "office_no");
                        },
                      });
                    }}
                  >
                    <MdOutlineDelete fontSize="small" />
                  </Controls.ActionButton>
                </div>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  };
  const showSocialMedia = () => {
    return (
      <>
        <label variant="h6" className="page-header">
          Social Media
        </label>
        <div className="row">
          {socialMedias.map((item, index) => (
            <div className="col-6" key={index}>
              <div className="service-card">
                <Typography variant="h6">{item.social_media}</Typography>
                <div>
                  <Controls.ActionButton
                    color="primary"
                    title="Update"
                    onClick={() => {
                      openInPopup(item, "social_media");
                    }}
                  >
                    <FiEdit fontSize="small" />
                  </Controls.ActionButton>
                  <Controls.ActionButton
                    color="secondary"
                    title="Delete"
                    onClick={() => {
                      setConfirmDialog({
                        isOpen: true,
                        title: "Are you sure to delete this service?",
                        subTitle: "You can't undo this operation",
                        onConfirm: () => {
                          onDelete(item.Id, "social_media");
                        },
                      });
                    }}
                  >
                    <MdOutlineDelete fontSize="small" />
                  </Controls.ActionButton>
                </div>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  };
  const showFaxNumber = () => {
    return (
      <>
        <label variant="h6" className="page-header">
          Fax number
        </label>
        <div className="row">
          {faxes.map((item, index) => (
            <div className="col-6" key={index}>
              <div className="service-card">
                <Typography variant="h6">{item.fax}</Typography>
                <div>
                  <Controls.ActionButton
                    color="primary"
                    title="Update"
                    onClick={() => {
                      openInPopup(item, "fax");
                    }}
                  >
                    <FiEdit fontSize="small" />
                  </Controls.ActionButton>
                  <Controls.ActionButton
                    color="secondary"
                    title="Delete"
                    onClick={() => {
                      setConfirmDialog({
                        isOpen: true,
                        title: "Are you sure to delete this service?",
                        subTitle: "You can't undo this operation",
                        onConfirm: () => {
                          onDelete(item.Id, "fax");
                        },
                      });
                    }}
                  >
                    <MdOutlineDelete fontSize="small" />
                  </Controls.ActionButton>
                </div>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  };
  const onDelete = (Id, method) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    deleteContactMethodRecord(method, Id).then((data) => {
      if (data.err) {
        NotifyMessage({
          message: data.err,
          type: "error",
        });
      } else {
        NotifyMessage({
          message: data.message,
          type: "success",
        });
        getAllContactmethods();
      }
    });
  };
  const data = {
    values,
    Id,
    validate,
    Field,
    handleInputChange,
    resetForm,
    setField,
    NotifyMessage,
    setValues,
    getAllContactmethods,
    errors,
    initialFValues,
    recordForEdit,
    setOpenPopup,
  };
  return (
    <div className="card">
      <Grid item xs={12}>
        <Typography variant="h5">Contact Methods</Typography>
      </Grid>
      <Button className="addFields" onClick={handleClick}>
        <IconContext.Provider value={{ className: "field_icon" }}>
          <GrAddCircle />
        </IconContext.Provider>
        Add Contact Method
      </Button>
      <Popover
        id={id}
        open={popover}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
      >
        <Select
          open={open}
          value={Field}
          onClose={handleClose}
          onChange={handleChange}
          name="field"
        >
          {field_list.map((item) => (
            <MenuItem key={item.Id} value={`${item.Id}-${item.label}`}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </Popover>

      {Object.keys(Field).length !== 0 && <AddNewContactForm data={data} />}
      {phoneNumbers.length != 0 && showPhoneNumber()}
      {officeNumbers.length != 0 && showOfficeNumber()}
      {socialMedias.length != 0 && showSocialMedia()}
      {faxes.length != 0 && showFaxNumber()}
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <Popup
        title="Contact Form"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <AddNewContactForm data={data} />
      </Popup>
    </div>
  );
};

export default ContactMethods;
