import ConfirmDialog from "../../components/customHelpers/ConfirmDialog";
import Controls from "../../components/controls/Controls";
import Norecords from "../../components/customHelpers/Norecords";
import Loading from "../../components/customHelpers/Loading";
import Popup from "../../components/customHelpers/Popup";
import Layout from "../../components/layout/Layout";
import { TableBody, TableRow, TableCell, Typography } from "@material-ui/core";
import { IoMdAdd } from "react-icons/io";
import { Grid, Toolbar, InputAdornment } from "@material-ui/core";
import { useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { MdOutlineDelete } from "react-icons/md";
import { AiOutlineSearch } from "react-icons/ai";
import useTable from "../../components/customHelpers/useTable";
import AddCallCenterForm from "../../components/forms/AddCallCenterForm";
import CallcenterActions from "../../actions/CallcenterActions";
import Notify from "../../components/customHelpers/Notify";
import Notification from "../../components/customHelpers/Notification";
import Admin from "../../components/auth/Admin";
const headCells = [
  { id: "fullName", label: "Full name", disableSorting: true },
  { id: "username", label: "Username" },
  { id: "email", label: "Email" },
  { id: "phone", label: "Phone No" },
  { id: "city", label: "City" },
  { id: "wereda", label: "Wereda" },
  { id: "subCity", label: "Sub city" },
  { id: "actions", label: "Actions", disableSorting: true },
];

const Callcenter = () => {
  const { NotifyMessage, notify, setNotify } = Notify();
  const { viewCallCenters, deleteCallCenter } = CallcenterActions();
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [values, setValues] = useState({
    loading: false,
    callCenters: [],
    reload: false,
  });
  const { loading, callCenters, reload } = values;
  useEffect(() => {
    getAllCallCenters();
  }, [reload]);
  const [openPopup, setOpenPopup] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const getAllCallCenters = () => {
    setValues({ ...values, loading: true });
    viewCallCenters().then((data) => {
      if (data.err) {
        NotifyMessage({
          message: data.err,
          type: "error",
        });
      } else if (data.result) {
        setValues({
          ...values,
          callCenters: data.result,
          loading: false,
        });
      } else {
        NotifyMessage({
          message: "Network time out,try again.",
          type: "error",
        });
        setValues({
          ...values,
          loading: false,
        });
      }
    });
  };
  const onDelete = (Id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    deleteCallCenter({ callcenterId: Id }).then((data) => {
      if (data.err) {
        NotifyMessage({
          message: data.err,
          type: "error",
        });
      } else {
        NotifyMessage({
          message: data.message,
          type: "success",
        });
        setValues({ ...values, reload: !reload });
      }
    });
  };
  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(
      callCenters.sort((a, b) =>
        a.createdAt < b.createdAt ? 1 : b.createdAt < a.createdAt ? -1 : 0
      ),
      headCells,
      filterFn
    );
  const openInPopup = (item) => {
    setRecordForEdit({ ...item, editing: true });
    setOpenPopup(true);
  };
  const handleSearch = (e) => {
    e.target.value = e.target.value.trimLeft();
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        const columns = [
          "firstName",
          "lastName",
          "middleName",
          "email",
          "subCity",
          "phone",
          "wereda",
          "city",
          "username",
        ];

        if (target.value == "") return items;
        else {
          return items.filter((x) => {
            return columns.some((column) => {
              return (
                x[column]
                  .toString()
                  .toLowerCase()
                  .indexOf(target.value.toLowerCase()) > -1
              );
            });
          });
        }
      },
    });
  };

  return (
    <Layout>
      <Admin>
        <div className="card">
          <Typography variant="h6" className="page-header">
            Call Center
          </Typography>

          <Toolbar>
            <Controls.Input
              label="Search Companies"
              className="searchInput"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AiOutlineSearch />
                  </InputAdornment>
                ),
              }}
              onChange={handleSearch}
            />

            <Controls.Button
              text="Add New"
              variant="outlined"
              startIcon={<IoMdAdd />}
              className="newButton"
              onClick={() => {
                setOpenPopup(true);
                setRecordForEdit(null);
              }}
            />
          </Toolbar>
          <Grid container>
            <Grid item xs={12} className="card__body">
              {loading ? (
                <Loading />
              ) : (
                <>
                  {" "}
                  <TblContainer>
                    <TblHead />
                    <TableBody>
                      {recordsAfterPagingAndSorting().length > 0 ? (
                        recordsAfterPagingAndSorting().map((item) => (
                          <TableRow key={item.Id}>
                            <TableCell>{`${item.firstName} ${item.middleName} ${item.lastName}`}</TableCell>
                            <TableCell>{item.username}</TableCell>
                            <TableCell>{item.email}</TableCell>
                            <TableCell>{item.phone}</TableCell>
                            <TableCell>{item.city}</TableCell>
                            <TableCell>{item.wereda}</TableCell>
                            <TableCell>{item.subCity}</TableCell>

                            <TableCell>
                              <Controls.ActionButton
                                color="primary"
                                title="Update"
                                onClick={() => {
                                  openInPopup(item);
                                }}
                              >
                                <FiEdit fontSize="medium" />
                              </Controls.ActionButton>
                              <Controls.ActionButton
                                color="secondary"
                                title="Delete"
                                onClick={() => {
                                  setConfirmDialog({
                                    isOpen: true,
                                    title:
                                      "Are you sure to delete this company record?",
                                    subTitle: "You can't undo this operation",
                                    onConfirm: () => {
                                      onDelete(item.Id);
                                    },
                                  });
                                }}
                              >
                                <MdOutlineDelete fontSize="medium" />
                              </Controls.ActionButton>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <Norecords col={8} />
                      )}
                    </TableBody>
                  </TblContainer>
                  <TblPagination />
                </>
              )}
            </Grid>
          </Grid>

          <Popup
            title="Add Call Center"
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
          >
            <AddCallCenterForm
              setOpenPopup={setOpenPopup}
              recordForEdit={recordForEdit}
              getAllCallCenters={getAllCallCenters}
              NotifyMessage={NotifyMessage}
            />
          </Popup>
          <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
          />
          <Notification notify={notify} setNotify={setNotify} />
        </div>
      </Admin>
    </Layout>
  );
};

export default Callcenter;
