import { Grid, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import Service from "../actions/Service";
import ServiceForm from "./forms/ServiceForm";
import { FiEdit } from "react-icons/fi";
import { MdOutlineDelete } from "react-icons/md";
import Controls from "./controls/Controls";
import ConfirmDialog from "./customHelpers/ConfirmDialog";
import Popup from "./customHelpers/Popup";
const ServiceComponent = ({ NotifyMessage, Id }) => {
  const { viewServices, deleteService } = Service();
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [values, setValues] = useState({
    loading: false,
    services: [],
    reload: false,
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const { loading, services, reload } = values;
  useEffect(() => {
    getAllServices();
  }, [reload]);
  const getAllServices = () => {
    setValues({ ...values, loading: true, error: false });
    viewServices(Id).then((data) => {
      if (data.err) {
        NotifyMessage({
          message: data.err,
          type: "error",
        });
      } else {
        setValues({
          ...values,
          services: data.result.sort((a, b) =>
            a.createdAt < b.createdAt ? 1 : b.createdAt < a.createdAt ? -1 : 0
          ),
          loading: false,
        });
      }
    });
  };
  const onDelete = (Id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    deleteService(Id).then((data) => {
      if (data.err) {
        NotifyMessage({
          message: data.err,
          type: "error",
        });
      } else {
        NotifyMessage({
          message: data.message,
          type: "success",
        });
        setValues({ ...values, reload: !reload });
      }
    });
  };
  const openInPopup = (item) => {
    setRecordForEdit({ ...item, editing: true });
    setOpenPopup(true);
  };

  return (
    <div className="card">
      <Grid item xs={12}>
        <Typography variant="h5">Services</Typography>
      </Grid>
      {recordForEdit == null && (
        <ServiceForm
          NotifyMessage={NotifyMessage}
          Id={Id}
          getAllServices={getAllServices}
          recordForEdit={recordForEdit}
        />
      )}
      <div className="row">
        {services.map((item, index) => (
          <div className="col-6" key={index}>
            <div className="service-card">
              <Typography variant="h6">{item.name}</Typography>
              <div>
                <Controls.ActionButton
                  color="primary"
                  title="Update"
                  onClick={() => {
                    openInPopup(item);
                  }}
                >
                  <FiEdit fontSize="small" />
                </Controls.ActionButton>
                <Controls.ActionButton
                  color="secondary"
                  title="Delete"
                  onClick={() => {
                    setConfirmDialog({
                      isOpen: true,
                      title: "Are you sure to delete this service?",
                      subTitle: "You can't undo this operation",
                      onConfirm: () => {
                        onDelete(item.Id);
                      },
                    });
                  }}
                >
                  <MdOutlineDelete fontSize="small" />
                </Controls.ActionButton>
              </div>
            </div>
          </div>
        ))}
      </div>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />{" "}
      <Popup
        title="Service Form"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        maxWidth="xl"
      >
        <ServiceForm
          setOpenPopup={setOpenPopup}
          recordForEdit={recordForEdit}
          getAllServices={getAllServices}
          NotifyMessage={NotifyMessage}
          setRecordForEdit={setRecordForEdit}
        />
      </Popup>
    </div>
  );
};

export default ServiceComponent;
