import fetch from "isomorphic-fetch";
import { useHistory } from "react-router-dom";
import { API } from "../config";

import { getCookie, userSessionExpired } from "./auth";
const CallcenterActions = () => {
  const history = useHistory();

  const addCallCenter = (callcenterInfo) => {
    return fetch(`${API}/add-call-center`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("token")}`,
      },
      body: JSON.stringify(callcenterInfo),
    })
      .then((response) => {
        userSessionExpired(response, history);
        return response.json();
      })
      .catch((err) => err);
  };
  const deleteCallCenter = (callcenterId) => {
    return fetch(`${API}/delete-call-center`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("token")}`,
      },
      body: JSON.stringify(callcenterId),
    })
      .then((response) => {
        userSessionExpired(response, history);
        return response.json();
      })
      .catch((err) => err);
  };
  const updateCallCenter = (callcenterInfo) => {
    return fetch(`${API}/update-call-center`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("token")}`,
      },
      body: JSON.stringify(callcenterInfo),
    })
      .then((response) => {
        userSessionExpired(response, history);
        return response.json();
      })
      .catch((err) => err);
  };
  const viewCallCenters = () => {
    return fetch(`${API}/get-all-call-centers`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("token")}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .catch((err) => err);
  };
  return { deleteCallCenter, addCallCenter, updateCallCenter, viewCallCenters };
};

export default CallcenterActions;
