import React, { useState } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { MdKeyboardArrowDown } from "react-icons/md";
import { Link } from "react-router-dom";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/system";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    // backgroundColor: "rgba(0,0,0,.8)",
    backgroundColor: theme.palette.common.black,
    fontSize: "12px",
  },
}));

export default function SubMenu(props) {
  return (
    <>
      <ListItemButton
        alignItems="flex-start"
        onClick={() => props.handleChangeEx(props.Id)}
        sx={{
          m: 0,
          p: 2.5,
          " &:focus": {
            "& svg": {
              transform:
                props.expanded != props.Id ? "rotate(-90deg)" : "rotate(0)",
              transition: "0.2s",
              opacity: 1,
            },
          },

          " &:hover": {
            backgroundColor: "rgba(20,61,89,.8)",
          },
          backgroundColor: "rgb(20,61,89)",
        }}
      >
        <ListItemText
          primary={
            props.name.length > 17 ? (
              <BootstrapTooltip title={props.name.toUpperCase()}>
                <span>{props.name.toUpperCase().substring(0, 17) + "..."}</span>
              </BootstrapTooltip>
            ) : (
              props.name.toUpperCase()
            )
          }
          primaryTypographyProps={{
            fontSize: 15,
            fontWeight: "medium",
            lineHeight: "20px",
          }}
          sx={{ my: 0 }}
        />
        <MdKeyboardArrowDown />
      </ListItemButton>
      {props.expanded == props.Id &&
        props.sub.map((item) => (
          <ListItemButton
            key={item.name}
            sx={{
              mx: 1,
              minHeight: 32,
              color: "white",
              backgroundColor: "rgba(244, 151, 3, 0.1)",
            }}
          >
            <ListItemText
              primary={
                <Link
                  to={`/companylist/${item.Id}`}
                  style={{ display: "block", padding: "7px 0px" }}
                  key={item.name}
                >
                  <span>
                    {item.name.length > 17 ? (
                      <BootstrapTooltip
                        title={
                          item.name.charAt(0).toUpperCase() + item.name.slice(1)
                        }
                      >
                        <span>
                          {" "}
                          {item.name.charAt(0).toUpperCase() +
                            item.name.slice(1).substring(0, 17) +
                            "..."}
                        </span>
                      </BootstrapTooltip>
                    ) : (
                      item.name.charAt(0).toUpperCase() + item.name.slice(1)
                    )}
                  </span>
                </Link>
              }
              primaryTypographyProps={{
                fontSize: 14,
                fontWeight: "medium",
              }}
            />
          </ListItemButton>
        ))}
    </>
  );
}
