import fetch from "isomorphic-fetch";
import { useHistory } from "react-router-dom";
import { API } from "../config";

import { getCookie, userSessionExpired } from "./auth";
const Service = () => {
  const history = useHistory();
  const addService = (service,Id) => {
    return fetch(`${API}/add-service/${Id}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("token")}`,
      },
      body: JSON.stringify(service),
    })
      .then((response) => {
        userSessionExpired(response, history);
        return response.json();
      })
      .catch((err) => err);
  };
  const updateService = (service) => {
    return fetch(`${API}/update-service/${service.Id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("token")}`,
      },
      body: JSON.stringify(service),
    })
      .then((response) => {
        userSessionExpired(response, history);
        return response.json();
      })
      .catch((err) => err);
  };
  const deleteService = (Id) => {
    return fetch(`${API}/delete-service/${Id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("token")}`,
      },
    })
      .then((response) => {
        userSessionExpired(response, history);
        return response.json();
      })
      .catch((err) => err);
  };
  const viewServices = (Id) => {
    return fetch(`${API}/view-services/${Id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("token")}`,
      },
    })
      .then((response) => {
        userSessionExpired(response, history);
        return response.json();
      })
      .catch((err) => err);
  };
  return {
    addService,
    updateService,
    deleteService,
    viewServices,
  };
};

export default Service;
