import Controls from "../controls/Controls";
import { Form, useForm } from "../customHelpers/useForm";
import { Grid } from "@material-ui/core";
import { useEffect } from "react";
import CallcenterActions from "../../actions/CallcenterActions";

const initialFValues = {
  firstName: "",
  middleName: "",
  lastName: "",
  phone: "",
  email: "",
  city: "",
  subCity: "",
  wereda: "",
  editing: false,
};
const AddCallCenterForm = ({
  recordForEdit,
  setOpenPopup,
  getAllCallCenters,
  NotifyMessage,
}) => {
  const { addCallCenter, updateCallCenter } = CallcenterActions();
  useEffect(() => {
    if (recordForEdit != null)
      setValues({
        ...recordForEdit,
        password: "",
      });
  }, [recordForEdit]);

  const validate = (fieldValues = values) => {
    const regexEmail =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const temp = { ...errors };
    if ("firstName" in fieldValues)
      temp.firstName =
        fieldValues.firstName.length != 0 ? "" : "This field is required.";
    if ("lastName" in fieldValues)
      temp.lastName =
        fieldValues.lastName.length != 0 ? "" : "This field is required.";
    if ("middleName" in fieldValues)
      temp.middleName =
        fieldValues.middleName.length != 0 ? "" : "This field is required.";
    if ("city" in fieldValues)
      temp.city = fieldValues.city.length != 0 ? "" : "This field is required.";
    if ("wereda" in fieldValues)
      temp.wereda =
        fieldValues.wereda.length != 0 ? "" : "This field is required.";
    if ("subCity" in fieldValues)
      temp.subCity =
        fieldValues.subCity.length != 0 ? "" : "This field is required.";
    if ("phone" in fieldValues) {
      var regex = /^\+(?:[0-9] ?){6,14}[0-9]$/;

      temp.phone = regex.test(fieldValues.phone)
        ? ""
        : "Enter a proper p hone number.";
    }
    if ("email" in fieldValues)
      temp.email =
        fieldValues.email.toString().length != 0
          ? ""
          : "This field is required.";
    if ("email" in fieldValues)
      temp.email =
        regexEmail.test(fieldValues.email.toString().toLowerCase()) &&
        fieldValues.email.length != 0
          ? ""
          : "Email is not valid.";
    if (values.editing == true) {
      if ("password" in fieldValues)
        temp.password =
          fieldValues.password.length == 0 || fieldValues.password.length > 7
            ? ""
            : "Password must be atleast 8 characters.";
      if ("username" in fieldValues)
        temp.username =
          fieldValues.username.length != 0 ? "" : "This field is required.";
    }
    setErrors({
      ...temp,
    });
    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);
  const handleSubmit = async (e) => {
    e.preventDefault();
    let callCenterInfo;
    if (recordForEdit != null)
      callCenterInfo = {
        ...values,
        username:
          recordForEdit.username == values.username
            ? undefined
            : values.username,
        password: values.password || undefined,
      };

    if (validate()) {
      setValues({ ...values, submitting: true });
      if (values.editing === false) {
        await addCallCenter(values).then((data) => {
          if (data.err) {
              setValues({ ...values, submitting: false });
            NotifyMessage({
              message: data.err,
              type: "error",
            });
          } else {
            NotifyMessage({
              message: data.message,
              type: "success",
              hide: false,
            });
            getAllCallCenters();
            setOpenPopup(false);
            resetForm();
          }
        });
      } else {
        await updateCallCenter(callCenterInfo).then((data) => {
          if (data.err) {
              setValues({ ...values, submitting: false });
            NotifyMessage({
              message: data.err,
              type: "error",
            });
          } else {
            NotifyMessage({
              message: data.message,
              type: "success",
            });
            getAllCallCenters();
            setOpenPopup(false);
            resetForm();
          }
        });
      }
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={6}>
          <Controls.Input
            name="firstName"
            label="First Name"
            value={values.firstName}
            onChange={handleInputChange}
            error={errors.firstName}
          />
          <Controls.Input
            name="middleName"
            label="Middle Name"
            value={values.middleName}
            onChange={handleInputChange}
            error={errors.middleName}
          />
          <Controls.Input
            name="lastName"
            label="last Name"
            value={values.lastName}
            onChange={handleInputChange}
            error={errors.lastName}
          />

          <Controls.Input
            label="Phone"
            name="phone"
            placeholder="+25191111111"
            value={values.phone}
            onChange={handleInputChange}
            error={errors.phone}
          />
          <Controls.Input
            label="Email"
            name="email"
            value={values.email}
            onChange={handleInputChange}
            error={errors.email}
          />

          <Controls.Input
            name="password"
            label="Password"
            disabled={values.editing == false ? true : false}
            value={values.password}
            onChange={handleInputChange}
            error={errors.password}
          />
          <Controls.Input
            name="username"
            label="Username"
            disabled={values.editing == false ? true : false}
            value={values.username}
            onChange={handleInputChange}
            error={errors.username}
          />
        </Grid>
        <Grid item xs={6}>
          <Controls.Input
            name="city"
            label="City"
            value={values.city}
            onChange={handleInputChange}
            error={errors.city}
          />
          <Controls.Input
            name="wereda"
            label="Wereda"
            value={values.wereda}
            onChange={handleInputChange}
            error={errors.wereda}
          />
          <Controls.Input
            name="subCity"
            label="Sub city"
            value={values.subCity}
            onChange={handleInputChange}
            error={errors.subCity}
          />
        </Grid>
        <Grid item xs>
          <div>
            <Controls.Button
              disabled={values.submitting ? true : false}
              text={
                values.editing == true
                  ? values.submitting
                    ? "Editing..."
                    : "Edit"
                  : values.submitting
                  ? "Adding..."
                  : "Add"
              }
              variant="contained"
              className="Button"
              type="submit"
            />
           
          </div>
        </Grid>
      </Grid>
    </Form>
  );
};
export default AddCallCenterForm;
