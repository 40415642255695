import { Typography } from "@material-ui/core";
import moment from "moment";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
const ViewNews = ({ newsToView }) => {

  return (
    <div>
      <Typography variant="h3">
        {newsToView.title.charAt(0).toUpperCase() + newsToView.title.slice(1)}
      </Typography>

      <p style={{ margin: "10px 0px" }}>
        Written by{" "}
        <span
          style={{
            fontSize: "14px",
            fontWeight: "600",
            color: "rgb(151, 150, 150)",
          }}
        >
          {newsToView.author}
        </span>{" "}
        | Published{" "}
        <span
          style={{
            fontSize: "14px",
            fontWeight: "600",
            color: "rgb(151, 150, 150)",
          }}
        >
          {moment(newsToView.createdAt).fromNow()}
        </span>
      </p>
      <img
        src={newsToView.image}
        alt={newsToView.image}
        className="news-headImage"
      />
      <ReactQuill readOnly={true} value={newsToView.body} theme="bubble" />

    </div>
  );
};

export default ViewNews;
