import React, { useState } from "react";
import "./layout.css";
import Sidebar from "../sidebar/Sidebar";
import TopNav from "../topnav/TopNav";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core";

import clsx from "clsx";

const drawerWidth = 270;
const useStyles = makeStyles((theme) => ({
  Content: {
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));
const Layout = ({ children }) => {
  const location = useLocation();
  const classes = useStyles();
  const [open, setOpen] = useState(true);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };
  return (
    <div className={`layout `}>
      <Sidebar open={open} location={location} />
      <div
        className={clsx(classes.Content, {
          [classes.contentShift]: open,
        })}
      >
        <div className="layout__content">
          <TopNav handleDrawerToggle={handleDrawerToggle} toggled={open} />
          <div className="layout__content-main">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
