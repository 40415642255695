import ConfirmDialog from "../../components/customHelpers/ConfirmDialog";
import Controls from "../../components/controls/Controls";
import Norecords from "../../components/customHelpers/Norecords";
import Loading from "../../components/customHelpers/Loading";
import Popup from "../../components/customHelpers/Popup";
import Layout from "../../components/layout/Layout";
import {
  TableBody,
  TableRow,
  TableCell,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { IoMdAdd } from "react-icons/io";
import { FaFileUpload } from "react-icons/fa";
import { Grid, Toolbar, InputAdornment } from "@material-ui/core";
import { useEffect, useState, useRef } from "react";
import { FiEdit } from "react-icons/fi";
import { MdOutlineDelete } from "react-icons/md";
import { AiOutlineSearch } from "react-icons/ai";
import useTable from "../../components/customHelpers/useTable";
import ImportFile from "../../actions/ImportFile";
import Catagory from "../../actions/Catagory";
import Notify from "../../components/customHelpers/Notify";
import Notification from "../../components/customHelpers/Notification";
import Admin from "../../components/auth/Admin";
import AddMaincatagory from "../../components/forms/AddMaincatagory";
import AddSubCatagory from "../../components/forms/AddSubCatagory";
import SaveFromFile from "../../components/SaveFromFile";

const headCells = [
  { id: "name", label: "Name" },
  { id: "image", label: "Image", disableSorting: true },
  { id: "parent", label: "Parent", disableSorting: true },
  { id: "actions", label: "Actions", disableSorting: true },
];
const useStyles = makeStyles((theme) => ({
  image: {
    borderRadius: "50%",
    height: "40px",
    width: "40px",
  },
}));
const makeData = (result) => {
  return result.map((data) => {
    return {
      name: data.name,
      image: data.image,
      createdAt: data.createdAt,
      Id: data.Id,
      parentId: data.parentId,
      parent: data.parent?.name || null,
      children: data.children || null,
    };
  });
};
const Catagories = () => {
  const classes = useStyles();
  const filePicker = useRef(null);
  const { NotifyMessage, notify, setNotify } = Notify();
  const [recordForEdit, setRecordForEdit] = useState(null);
  const { viewAllCatagories, deleteCatagory, viewAllCatagoriesWithChildren } =
    Catagory();

  const { data, colDefs, importExcel, setData } = ImportFile();
  const [values, setValues] = useState({
    loading: true,
    catagories: [],
    reload: false,
  });
  const [openPopupBulkSave, setOpenPopupBulkSave] = useState(false);
  const [Id, setId] = useState("");
  const [record, setRecord] = useState({
    data: [],
    colDefs: [],
  });
  const { loading, catagories, reload } = values;
  useEffect(() => {
    getAllCatagories();
  }, [reload]);
  const [openPopup, setOpenPopup] = useState(false);
  const [openPopupSubCatagory, setOpenPopupSubCatagory] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const getAllCatagories = async () => {
    setValues({ ...values, loading: true, error: false });
    await viewAllCatagories().then((data) => {
      if (data.err) {
        NotifyMessage({
          message: data.err,
          type: "error",
        });
      } else if (data.result) {
        getAllcatagoriesWithChildren(makeData(data.result));
      }
    });
  };
  const getAllcatagoriesWithChildren = async (catagory) => {
    setValues({ ...values, loading: true, error: false });

    await viewAllCatagoriesWithChildren().then((data) => {
      if (data.err) {
        NotifyMessage({
          message: data.err,
          type: "error",
        });
      } else if (data.result) {
        setup(catagory, makeData(data.result));
      }
    });
  };

  const setup = async (data, datawithchild) => {
    let tempArray = [];
    await datawithchild.forEach((catagorychild) => {
      if (catagorychild.parentId != null) {
        data.forEach((catagory) => {
          if (catagorychild.parentId == catagory.Id) {
            tempArray.push({
              ...catagorychild,
              parent: catagory.name,
            });
          } else {
            return;
          }
        });
      } else {
        tempArray.push({
          ...catagorychild,
          parent: null,
        });
      }
    });
    setValues({
      ...values,
      catagories: tempArray,
      loading: false,
    });
  };
  const onDelete = (Id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    deleteCatagory({ catagoryId: Id }).then((data) => {
      if (data.err) {
        NotifyMessage({
          message: data.err,
          type: "error",
        });
        setValues({ loading: false, error: data.err });
      } else {
        NotifyMessage({
          message: data.message,
          type: "success",
        });
        setValues({ ...values, reload: !reload });
      }
    });
  };
  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(
      catagories.sort((a, b) =>
        a.createdAt < b.createdAt ? 1 : b.createdAt < a.createdAt ? -1 : 0
      ),
      headCells,
      filterFn
    );
  const openInPopup = (item) => {
    setRecordForEdit({ ...item, editing: true });
    setOpenPopup(true);
  };
  const openInPopupSubCatagory = (item) => {
    setRecordForEdit({ ...item, editing: true });
    setOpenPopupSubCatagory(true);
  };

  if (data && data.length != 0) {
    setRecord({ data, colDefs });

    setData([]);
    setOpenPopupBulkSave(true);
  }

  const handleSearch = (e) => {
    e.target.value = e.target.value.trimLeft();
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        const columns = ["name", "parent"];

        if (target.value == "") return items;
        else {
          return items.filter((x) => {
            return columns.some((column) => {
              if (x[column]) {
                return (
                  x[column]
                    .toString()
                    .toLowerCase()
                    .indexOf(target.value.toLowerCase()) > -1
                );
              }
            });
          });
        }
      },
    });
  };
  return (
    <Layout>
      <Admin>
        <div className="card">
          <Typography variant="h6" className="page-header">
            Catagories
          </Typography>
          <Toolbar>
            <Controls.Input
              label="Search Catagories"
              className="searchInput"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AiOutlineSearch />
                  </InputAdornment>
                ),
              }}
              onChange={handleSearch}
            />

            <Controls.Button
              text="Main Catagory"
              variant="outlined"
              startIcon={<IoMdAdd />}
              className="newButton"
              onClick={() => {
                setOpenPopup(true);
                setRecordForEdit(null);
              }}
            />
            <Controls.Button
              text="Sub Catagory"
              variant="outlined"
              startIcon={<IoMdAdd />}
              className="newButton"
              onClick={() => {
                setOpenPopupSubCatagory(true);
                setRecordForEdit(null);
              }}
            />
          </Toolbar>
          <Grid container>
            <Grid item xs={12} className="card__body">
              {loading ? (
                <Loading />
              ) : (
                <>
                  {" "}
                  <TblContainer>
                    <TblHead />
                    <TableBody>
                      {recordsAfterPagingAndSorting().length > 0 ? (
                        recordsAfterPagingAndSorting().map((item) => (
                          <TableRow key={item.Id}>
                            <TableCell>{item.name}</TableCell>
                            <TableCell>
                              <img
                                className={classes.image}
                                src={item.image}
                                alt={item.image}
                              />
                            </TableCell>
                            <TableCell>{item.parent}</TableCell>
                            <TableCell>
                              <Controls.ActionButton
                                color="primary"
                                title="Update"
                                onClick={() => {
                                  item.parentId == null
                                    ? openInPopup(item)
                                    : openInPopupSubCatagory(item);
                                }}
                              >
                                <FiEdit fontSize="medium" />
                              </Controls.ActionButton>
                              <Controls.ActionButton
                                color="secondary"
                                title="Delete"
                                onClick={() => {
                                  setConfirmDialog({
                                    isOpen: true,
                                    title:
                                      "Are you sure to delete this company record?",
                                    subTitle: "You can't undo this operation",
                                    onConfirm: () => {
                                      onDelete(item.Id);
                                    },
                                  });
                                }}
                              >
                                <MdOutlineDelete fontSize="medium" />
                              </Controls.ActionButton>
                              {item.children && item.children.length == 0 && (
                                <>
                                  <Controls.ActionButton
                                    color="primary"
                                    title="Import file"
                                    onClick={() => {
                                      filePicker.current.click();
                                      setId(item.Id);
                                    }}
                                  >
                                    <FaFileUpload fontSize="medium" />
                                  </Controls.ActionButton>
                                  <input
                                    label="Icon"
                                    name="image"
                                    type="file"
                                    hidden
                                    ref={filePicker}
                                    onChange={importExcel}
                                    autoFocus={true}
                                  />
                                </>
                              )}
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <Norecords col={4} />
                      )}
                    </TableBody>
                  </TblContainer>
                  <TblPagination />
                </>
              )}
            </Grid>
          </Grid>
        </div>
        <Popup
          title="Main Catagory Form"
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
        >
          <AddMaincatagory
            NotifyMessage={NotifyMessage}
            setOpenPopup={setOpenPopup}
            getAllCatagories={getAllCatagories}
            recordForEdit={recordForEdit}
          />
        </Popup>
        <Popup
          title="Sub Catagory Form"
          openPopup={openPopupSubCatagory}
          setOpenPopup={setOpenPopupSubCatagory}
        >
          <AddSubCatagory
            NotifyMessage={NotifyMessage}
            setOpenPopup={setOpenPopupSubCatagory}
            getAllCatagories={getAllCatagories}
            recordForEdit={recordForEdit}
          />
        </Popup>
        <Popup
          title="Import Company"
          openPopup={openPopupBulkSave}
          setOpenPopup={setOpenPopupBulkSave}
          maxWidth={false}
        >
          <SaveFromFile
            Id={Id}
            file={record}
            NotifyMessage={NotifyMessage}
            setOpenPopupBulkSave={setOpenPopupBulkSave}
          />
        </Popup>
        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
        <Notification notify={notify} setNotify={setNotify} />
      </Admin>
    </Layout>
  );
};

export default Catagories;
