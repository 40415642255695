import { Grid, makeStyles } from "@material-ui/core";
import Controls from "../controls/Controls";
import { Form, useForm } from "../customHelpers/useForm";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useEffect, useRef, useState } from "react";
import News from "../../actions/News";
import { Alert } from "@material-ui/lab";
import { Document, Page, pdfjs } from "react-pdf";
import Popup from "../customHelpers/Popup";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const initialFValues = {
  body: "",
  title: "",
  image: null,
  author: "",
  editing: false,
};

const QuillModules = {
  toolbar: [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],
    ["image", "video", "link"],
    [{ header: 1 }, { header: 2 }, { header: [1, 2, 3, 4, 5, 6, false] }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction
    [{ size: ["small", "large", "huge", false] }], // custom dropdown
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],
    ["clean"],
  ],
};
const QuillFormats = [
  "background",
  "bold",
  "color",
  "font",
  "code",
  "italic",
  "link",
  "size",
  "strike",
  "script",
  "underline",
  "blockquote",
  "header",
  "indent",
  "list",
  "align",
  "direction",
  "code-block",
  "formula",
  "image",
  "video",
];

const useStyles = makeStyles((theme) => ({
  image: {
    height: "400px",
    width: "100%",
    margin: "10px 0px",
  },
}));
const NewsForm = ({
  NotifyMessage,
  setOpenPopup,
  recordForEdit,
  getAllNews,
  update,
}) => {
  
function slugify(str) {
  str = str.replace(/^\s+|\s+$/g, "");

  // Make the string lowercase
  str = str.toLowerCase();

  // Remove accents, swap ñ for n, etc
  var from =
    "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;";
  var to =
    "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------";
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  // Remove invalid chars
  str = str
    .replace(/[^a-z0-9 -]/g, "")
    // Collapse whitespace and replace by -
    .replace(/\s+/g, "-")
    // Collapse dashes
    .replace(/-+/g, "-");

  return str;
}
  useEffect(() => {
    if (recordForEdit != null) {
      setValues({
        ...recordForEdit,
      });
      setfile(recordForEdit.image);
    }
  }, [recordForEdit]);
  const classes = useStyles();
  const roomImagePicker = useRef(null);
  const [file, setfile] = useState(null);
  const { addNews, updateNews } = News();
  const [readerPopup, setOpenReaderPopup] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1.0);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }
  const validate = (fieldValues = values) => {
    const temp = { ...errors };
    if ("body" in fieldValues)
      temp.body =
        fieldValues.body.length != 0 && fieldValues.body != "<p><br></p>"
          ? ""
          : "The body of the news can not be empty.";
    if ("title" in fieldValues)
      temp.title =
        fieldValues.title.length != 0 ? "" : "This field is required.";
    if ("author" in fieldValues)
      temp.author =
        fieldValues.author.length != 0 ? "" : "This field is required.";
    if (values.editing == false) {
      if (values.editing == false) {
        if ("image" in fieldValues)
          temp.image =
            fieldValues.image != null ? "" : "This field is required.";
      }
    }
    setErrors({
      ...temp,
    });
    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(recordForEdit || initialFValues, true, validate);
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append(
      "image",
      values.image instanceof File ? values.image : null
    );
    formData.append("title", values.title);
    formData.append("body", values.body);
    formData.append("author", values.author);
    if (validate()) {
      setValues({ ...values, submitting: true });
      if (values.editing == false) {
        addNews(formData).then((data) => {
          if (data.err) {
            setValues({ ...values, submitting: false });
            NotifyMessage({
              message: data.err,
              type: "error",
            });
          } else {
            NotifyMessage({
              message: data.message,
              type: "success",
            });
            setOpenPopup(false);
            resetForm();
            getAllNews();
          }
        });
      } else {
        updateNews(formData, values.Id).then((data) => {
          if (data.err) {
            setValues({ ...values, submitting: false });
            NotifyMessage({
              message: data.err,
              type: "error",
            });
          } else {
            NotifyMessage({
              message: data.message,
              type: "success",
            });
            setOpenPopup(false);
            resetForm();
            getAllNews();
          }
        });
      }
    }
  };
  const handleBody = (name, value) => ({
    target: {
      name,
      value,
    },
  });

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={6}>
          <Controls.Input
            name="title"
            label="Title"
            value={values.title}
            onChange={handleInputChange}
            error={errors.title}
          />
        </Grid>
        <Grid item xs={6}>
          <Controls.Input
            name="author"
            label="Author"
            value={values.author}
            onChange={handleInputChange}
            error={errors.author}
          />
        </Grid>
        {update && (
          // <Grid item xs={6} className="grid-input">
          <Controls.Button
            onClick={() => {
              setOpenReaderPopup(true);
            }}
            text="View licence"
            variant="contained"
            className="Button"
          />
          // </Grid>
        )}
        <Grid item xs={12}>
          {values.editing == true ? (
            <>
              <Controls.Button
                color="primary"
                onClick={() => {
                  roomImagePicker.current.click();
                }}
                variant="outlined"
                text="Change headline image"
                className="newButton"
              />
              <input
                label="Icon"
                name="image"
                type="file"
                hidden
                accept="image/*"
                ref={roomImagePicker}
                onChange={handleInputChange}
              />
              <img src={file} alt={file} className={classes.image} />
            </>
          ) : (
            <Controls.Input
              name="image"
              type="file"
              label="Headline image"
              onChange={handleInputChange}
              error={errors.image}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          {" "}
          <ReactQuill
            modules={QuillModules}
            formats={QuillFormats}
            value={values.body}
            placeholder="what's on your mind"
            onChange={(e) => handleInputChange(handleBody("body", e))}
            theme="snow"
          />{" "}
          {errors.body && <Alert severity="error">{errors.body}</Alert>}
        </Grid>
        <Grid item xs={12}>
          <Controls.Button
            disabled={values.submitting ? true : false}
            text={
              values.editing == true
                ? values.submitting
                  ? "Editing..."
                  : "Edit"
                : values.submitting
                ? "Publishing..."
                : "Publish"
            }
            variant="contained"
            className="Button"
            type="submit"
          />
        </Grid>
      </Grid>
      <Popup
        title="Licence"
        openPopup={readerPopup}
        setOpenPopup={setOpenReaderPopup}
      >
        <Document
          style={{ border: "5px solid black" }}
          file={values.licence}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page pageNumber={pageNumber} scale={scale} />
        </Document>{" "}
      </Popup>
    </Form>
  );
};

export default NewsForm;
