import React, { useState } from "react";

import { Link } from "react-router-dom";
import SubMenu from "./SubMenu";
import Box from "@mui/material/Box";
import { createTheme, styled, ThemeProvider, Typography } from "@mui/material";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import { MdKeyboardArrowDown } from "react-icons/md";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    // backgroundColor: "rgba(0,0,0,.8)",
    backgroundColor: theme.palette.common.black,
    fontSize: "12px",
  },
}));

const FireNav = styled(List)({
  "& .MuiListItemButton-root": {
    paddingLeft: 24,
    paddingRight: 24,
  },
  "& .MuiListItemIcon-root": {
    minWidth: 0,
    marginRight: 16,
  },
  "& .MuiSvgIcon-root": {
    fontSize: 20,
  },
});
export default function Menu(props) {
  const [expanded, setExpanded] = React.useState("");
  const handleChangeEx = (panel) => {
    setExpanded(expanded != panel ? panel : false);
  };
  return props.sub.length == 0 ? (
    <ListItemButton
      sx={{
        minHeight: 32,
        color: "white",
        // backgroundColor: "rgba(244, 151, 3, 0.5)",
      }}
    >
      <ListItemText
        primary={
          <Link
            to={`/companylist/${props.Id}`}
            style={{ display: "block", padding: "7px 0px" }}
          >
            <span>
              {props.name.length > 17 ? (
                <BootstrapTooltip
                  title={
                    props.name.charAt(0).toUpperCase() + props.name.slice(1)
                  }
                >
                  <span>
                    {" "}
                    {props.name.charAt(0).toUpperCase() +
                      props.name.slice(1).substring(0, 17) +
                      "..."}
                  </span>
                </BootstrapTooltip>
              ) : (
                props.name.charAt(0).toUpperCase() + props.name.slice(1)
              )}
            </span>
          </Link>
        }
        primaryTypographyProps={{
          fontSize: 14,
          fontWeight: "medium",
        }}
        sx={{ my: 0, px: "24px" }}
      />
    </ListItemButton>
  ) : (
    <>
      <ListItemButton
        alignItems="flex-start"
        onClick={() => props.handleChangeEx(props.Id)}
        sx={{
          p: 2.5,

          " &:focus": {
            "& svg": {
              transform:
                props.expanded != props.Id ? "rotate(-90deg)" : "rotate(0)",
              transition: "0.2s",
              opacity: 1,
            },
          },

          " &:hover": {
            backgroundColor: "rgba(20,61,89,.8)",
          },
          backgroundColor: "rgb(20,61,89)",
        }}
      >
        <ListItemText
          primary={
            props.name.length > 17 ? (
              <BootstrapTooltip title={props.name.toUpperCase()}>
                <span style={{ display: "block" }}>
                  {props.name.toUpperCase().substring(0, 17) + "..."}
                </span>
              </BootstrapTooltip>
            ) : (
              props.name.toUpperCase()
            )
          }
          primaryTypographyProps={{
            fontSize: 15,
            fontWeight: "medium",
            lineHeight: "20px",
          }}
          sx={{ my: 0, px: "24px", color: "white" }}
        />
        <MdKeyboardArrowDown style={{ marginRight: "24px" }} />
      </ListItemButton>
      {props.expanded == props.Id &&
        props.sub.map((item) => (
          <ListItemButton
            key={item.Id}
            sx={{
              minHeight: 32,
              color: "white",
              p: 0,
              backgroundColor: "rgba(244, 151, 3, 0.3)",
            }}
          >
            <ListItemText
              primary={
                item.children.length == 0 ? (
                  <Link
                    to={`/companylist/${item.Id}`}
                    style={{ display: "block", padding: "18px 24px" }}
                    key={item.name}
                  >
                    <span>
                      {item.name.length > 17 ? (
                        <BootstrapTooltip
                          title={
                            item.name.charAt(0).toUpperCase() +
                            item.name.slice(1)
                          }
                        >
                          <span>
                            {" "}
                            {item.name.charAt(0).toUpperCase() +
                              item.name.slice(1).substring(0, 17) +
                              "..."}
                          </span>
                        </BootstrapTooltip>
                      ) : (
                        item.name.charAt(0).toUpperCase() + item.name.slice(1)
                      )}
                    </span>
                  </Link>
                ) : (
                  <Box sx={{ display: "flex" }}>
                    <ThemeProvider
                      theme={createTheme({
                        components: {
                          MuiListItemButton: {
                            defaultProps: {
                              disableTouchRipple: true,
                            },
                          },
                        },
                        palette: {
                          mode: "dark",
                          background: { paper: "transparent" },
                        },
                      })}
                    >
                      <Paper
                        elevation={0}
                        sx={{
                          width: "100%",
                        }}
                      >
                        <FireNav
                          component="nav"
                          disablePadding
                          sx={{
                            color: "white",
                          }}
                        >
                          <SubMenu
                            key={item.name}
                            name={item.name}
                            sub={item.children || []}
                            handleChangeEx={handleChangeEx}
                            expanded={expanded}
                            Id={item.Id}
                          />
                        </FireNav>
                      </Paper>
                    </ThemeProvider>
                  </Box>
                )
              }
              sx={{
                my: 0,
                px: 1,
              }}
              primaryTypographyProps={{
                fontSize: 14,
                fontWeight: "medium",
              }}
            />
          </ListItemButton>
        ))}
    </>
  );
}
