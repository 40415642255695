import fetch from "isomorphic-fetch";
import { useHistory } from "react-router-dom";
import { API } from "../config";

import { getCookie, userSessionExpired } from "./auth";
const User = () => {
  const history = useHistory();
  const deleteUser = (Id) => {
    return fetch(`${API}/delete-user/${Id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("token")}`,
      },
    })
      .then((response) => {
        userSessionExpired(response, history);
        return response.json();
      })
      .catch((err) => err);
  };
   const viewUsers = () => {
     return fetch(`${API}/get-all-users`, {
       method: "GET",
       headers: {
         Accept: "application/json",
         "Content-Type": "application/json",
         Authorization: `Bearer ${getCookie("token")}`,
       },
     })
       .then((response) => {
         return response.json();
       })
       .catch((err) => err);
   };
   const totalUsers = () => {
     return fetch(`${API}/total-users`, {
       method: "GET",
       headers: {
         Accept: "application/json",
         "Content-Type": "application/json",
       },
     })
       .then((response) => {
         return response.json();
       })
       .catch((err) => err);
   };
  return { deleteUser, viewUsers, totalUsers };
};

export default User;
