import React from "react";
import { Fab, Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import { BsTrash } from "react-icons/bs";
import { BsArrowRight } from "react-icons/bs";
import Admin from "../../components/auth/Admin";
import Controls from "../../components/controls/Controls";
import Layout from "../../components/layout/Layout";
import AdsActions from "../../actions/Ads";
import Notify from "../../components/customHelpers/Notify";
import Notification from "../../components/customHelpers/Notification";
import Loading from "../../components/customHelpers/Loading";
import Popup from "../../components/customHelpers/Popup";
import ConfirmDialog from "../../components/customHelpers/ConfirmDialog";

const Ads = () => {
  const { viewAllAds, addAd, deleteAd } = AdsActions();
  const AdSpace = React.useRef("");
  const [AdsRecord, setAdsRecord] = React.useState([]);
  const { NotifyMessage, notify, setNotify } = Notify();
  const [values, setValues] = React.useState({
    loading: false,
    spaceOneAds: [],
    spaceTwoAds: [],
    spaceThreeAds: [],
    reload: false,
  });
  const [confirmDialog, setConfirmDialog] = React.useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [openPopup, setOpenPopup] = React.useState(false);
  const roomImagePicker = React.useRef(null);
  const { loading, spaceOneAds, spaceTwoAds, spaceThreeAds } = values;
  React.useEffect(() => {
    getAllAds();
  }, []);
  const getAllAds = () => {
    setValues({ ...values, loading: true });
    viewAllAds().then((data) => {
      if (data.err) {
        NotifyMessage({
          message: data.err,
          type: "error",
        });
      } else {
        const adOne = data.result.filter((ad) => ad.adSpace == "one");
        const adTwo = data.result.filter((ad) => ad.adSpace == "two");
        const adThree = data.result.filter((ad) => ad.adSpace == "three");
        setAdsRecord(
          AdSpace.current == "one"
            ? adOne
            : AdSpace.current == "two"
            ? adTwo
            : adThree
        );
        setValues({
          ...values,
          loading: false,
          spaceOneAds: adOne,
          spaceTwoAds: adTwo,
          spaceThreeAds: adThree,
        });
      }
    });
  };
const[link,setLink]=React.useState('')
const handleChangeLink=(e)=>{
  setLink(e.target.value)
}
const[resolution,setResolution]=React.useState('')
const handleResolution=(e)=>{
setResolution(e.target.value)
}
  const handleFileChange = (e) => {
    const formData = new FormData();
    setValues({ ...values, submitting: true });
    formData.append("image", e.target.files[0]);
    formData.append("adSpace", AdSpace.current);
    formData.append("link",link)
    formData.append("resolution",resolution)
    addAd(formData).then((data) => {
      if (data.err) {
        setValues({ ...values, submitting: false });
        NotifyMessage({
          message: data.err,
          type: "error",
        });
      } else {
        NotifyMessage({
          message: data.message,
          type: "success",
        });
        setLink('')
        setResolution('')
        getAllAds();
      }
    });
  };
  const onDelete = (Id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    deleteAd(Id).then((data) => {
      if (data.err) {
        NotifyMessage({
          message: data.err,
          type: "error",
        });
      } else {
        NotifyMessage({
          message: data.message,
          type: "success",
        });
        getAllAds();
      }
    });
  };
  return (
    <Layout>
      <Admin>
        <div className="card">
          <Typography variant="h6" className="page-header">
            Ads
          </Typography>
          <Grid container>
            <Grid item xs={12} className="card__body">
              {loading ? (
                <Loading />
              ) : (
                <div className="ads__holder">
                  <div className="ads__card">
                    <div className="front"></div>
                    <p className="card_title">Ads</p>
                    <p className="card_subline">Ad space one</p>
                    <div
                      className="card_arrow"
                      onClick={() => {
                        AdSpace.current = "one";
                        setOpenPopup(true);
                        setAdsRecord(spaceOneAds);
                      }}
                    >
                      <BsArrowRight fontSize="large" />
                    </div>
                  </div>
                  <div className="ads__card">
                    <div className="front"></div>
                    <p className="card_title">Ads</p>
                    <p className="card_subline">Ad space two</p>

                    <div
                      className="card_arrow"
                      onClick={() => {
                        AdSpace.current = "two";
                        setOpenPopup(true);
                        setAdsRecord(spaceTwoAds);
                      }}
                    >
                      <BsArrowRight fontSize="large" />
                    </div>
                  </div>
                  <div className="ads__card">
                    <div className="front"></div>
                    <p className="card_title">Ads</p>
                    <p className="card_subline">Ad space three</p>

                    <div
                      className="card_arrow"
                      onClick={() => {
                        AdSpace.current = "three";
                        setOpenPopup(true);
                        setAdsRecord(spaceThreeAds);
                      }}
                    >
                      <BsArrowRight fontSize="large" />
                    </div>
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
        </div>
        <Popup
          title={`Add Space ${AdSpace.current}`}
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
        >
          <div>
            <Grid container>
              <Grid item xs={4}>
              <Controls.Input
                value={link}
                onChange={handleChangeLink}
                name="link"
                label="Add Link"
              />
              </Grid>
              <span style={{marginLeft:"20px"}}>
                <Grid item xs={12}>
              <Controls.Input
                value={resolution}
                onChange={handleResolution}
                name="resolution"
                label="resolution"
              />
              </Grid>
              </span>
              <div style={{marginLeft:"20px"}}>
              <Grid xs={12}>
              <Controls.Button
                color="primary"
                onClick={() => {
                  roomImagePicker.current.click();
                }}
                variant="outlined"
                text="Add Ad Image"
                className="newButton"
              />
              </Grid>
              </div>
            </Grid>
            <input
              label="Logo"
              name="image"
              type="file"
              hidden
              accept="image/*"
              ref={roomImagePicker}
              onChange={handleFileChange}
              autoFocus={true}
            />

            {AdsRecord.map((ad) => {
              return (
                <div className="image__card">
                  <img src={ad.imageURI} className="ad__image" />
                  <Tooltip title="Delete Ad">
                    <Fab
                      color="secondary"
                      className="ad__delete__icon"
                      onClick={() => {
                        setConfirmDialog({
                          isOpen: true,
                          title: "Are you sure to delete this image?",
                          subTitle: "You can't undo this operation",
                          onConfirm: () => {
                            onDelete(ad.Id);
                          },
                        });
                      }}
                    >
                      <BsTrash fontSize="large" />
                    </Fab>
                  </Tooltip>
                </div>
              );
            })}
          </div>
        </Popup>
        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
        <Notification notify={notify} setNotify={setNotify} />
      </Admin>
    </Layout>
  );
};
export default Ads;
