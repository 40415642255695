import React, { useState, useEffect } from "react";

import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {
  staffSignin,
  authentication,
  isAuth,
  adminForgetPassword,
} from "../../actions/auth";
import { withRouter } from "react-router-dom";
import { Alert } from "@material-ui/lab";
import {
  CircularProgress,
  InputAdornment,
  makeStyles,
} from "@material-ui/core";
import { BsPersonFill } from "react-icons/bs";
import { AiFillLock } from "react-icons/ai";
import Controls from "../controls/Controls";
import logo from "../../assets/images/logo.png";
import { useForm } from "../customHelpers/useForm";
import Notify from "../customHelpers/Notify";
import Notification from "../customHelpers/Notification";

const useStyles = makeStyles((theme) => ({
  fields: {
    width: "100%",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  image: {
    maxHeight: "50%",
    maxWidth: "50%",
  },
  logging: {
    marginLeft: "10px",
  },
}));
const initialFValues = {
  username: "",
  password: "",
  logginError: "",
};
const SigninComponent = (props) => {
  const { NotifyMessage, notify, setNotify } = Notify();

  const [loading, setLoading] = useState(false);
  const [showForgetPassword, setShowForgetPassword] = useState(false);

  const classes = useStyles();
  const { history } = props;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("username" in fieldValues)
      temp.username =
        fieldValues.username.length != 0 ? "" : "This field is required.";
    if ("password" in fieldValues)
      temp.password =
        fieldValues.password.length != 0 ? "" : "This field is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);
  const handleForgetPassword = () => {
    adminForgetPassword().then((data) => {
      if (data.err) {
        NotifyMessage({
          message: data.err,
          type: "error",
        });
      } else {
        NotifyMessage({
          message: data.message,
          type: "success",
        });
      }
    });
  };
  const HandleSubmit = (e) => {
    e.preventDefault();
    setShowForgetPassword(false);
    setLoading(true);
    if (validate()) {
      staffSignin(values).then((data) => {
        if (data.err) {
          setValues({ ...values, logginError: data.err });
          setLoading(false);
          if (
            data.err == "Password is incorrect" &&
            values.username == "admin"
          ) {
            setShowForgetPassword(true);
          }
        } else {
          if (data.user && data.token) {
            authentication(data, () => {
              if (isAuth() && isAuth().role === 1) {
                history.push(`/admin`);
              } else if (isAuth() && isAuth().role === 2) {
                history.push(`/`);
              }
            });
          } else {
            setValues({ ...values, logginError: "Something's not right." });
            setLoading(false);
          }
        }
      });
    }
  };

  useEffect(() => {
    if (isAuth() && isAuth().role == 1) history.push("/admin");
    else if (isAuth() && isAuth().role == 2) history.push("/");
  }, []);
  return (
    <div className="h-screen bg-gray-200 flex justify-center items-center">
      <Card className="max-w-sm">
        <div className="flex justify-center align-center">
          <img src={logo} className={classes.image} />
        </div>
        <CardContent>
          <div className="mb-8 ">
            <Typography variant="h5" color="primary">
              Login
            </Typography>
            Signin to your account
          </div>
          {history.location.state && (
            <Alert severity="error">{history.location.state}</Alert>
          )}
          {values.logginError && (
            <Alert severity="error">{values.logginError}</Alert>
          )}

          <form onSubmit={HandleSubmit}>
            <Controls.Input
              label="Username"
              value={values.username}
              name="username"
              className={classes.fields}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <BsPersonFill color="primary" />
                  </InputAdornment>
                ),
              }}
              onChange={handleInputChange}
              error={errors.username}
            />
            <Controls.Input
              label="Password"
              value={values.password}
              name="password"
              className={classes.fields}
              type="password"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AiFillLock color="primary" />
                  </InputAdornment>
                ),
              }}
              onChange={handleInputChange}
              error={errors.password}
            />

            <CardActions>
              <Controls.Button
                color="primary"
                type="submit"
                text={
                  <>
                    Login
                    {loading && (
                      <CircularProgress
                        size="15px"
                        color="secondary"
                        className={classes.logging}
                      />
                    )}
                  </>
                }
              />
              {showForgetPassword && (
                <Typography
                  onClick={handleForgetPassword}
                  className="forget__password"
                >
                  forget password?
                </Typography>
              )}
            </CardActions>
          </form>
        </CardContent>
      </Card>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

export default withRouter(SigninComponent);
