import fetch from "isomorphic-fetch";
import { useHistory } from "react-router-dom";
import { API } from "../config";

import { getCookie, userSessionExpired } from "./auth";
const Catagory = () => {
  const history = useHistory();
  const addCatagory = (catagory) => {
    return fetch(`${API}/add-catagory`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getCookie("token")}`,
      },
      body: catagory,
    })
      .then((response) => {
        userSessionExpired(response, history);
        return response.json();
      })
      .catch((err) => err);
  };
  const updateCatagory = (catagory, Id) => {
    return fetch(`${API}/update-catagory/${Id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getCookie("token")}`,
      },
      body: catagory,
    })
      .then((response) => {
        userSessionExpired(response, history);
        return response.json();
      })
      .catch((err) => err);
  };
  const deleteCatagory = (catagoryId) => {
    return fetch(`${API}/delete-catagory`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("token")}`,
      },
      body: JSON.stringify(catagoryId),
    })
      .then((response) => {
        userSessionExpired(response, history);
        return response.json();
      })
      .catch((err) => err);
  };
  const viewMainCatagories = () => {
    return fetch(`${API}/view-main-catagories`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        userSessionExpired(response, history);
        return response.json();
      })
      .catch((err) => err);
  };
  const viewSubCatagories = (Id) => {
    return fetch(`${API}/view-sub-catagories`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(Id),
    })
      .then((response) => {
        userSessionExpired(response, history);
        return response.json();
      })
      .catch((err) => err);
  };
  const companiesList = (Id) => {
    return fetch(`${API}/view-catagory/${Id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        userSessionExpired(response, history);
        return response.json();
      })
      .catch((err) => err);
  };
  const viewAllCatagories = () => {
    return fetch(`${API}/view-all-catagories`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        userSessionExpired(response, history);
        return response.json();
      })
      .catch((err) => err);
  };
  const viewAllCatagoriesWithChildren = () => {
    return fetch(`${API}/view-all-catagories-with-children`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        userSessionExpired(response, history);
        return response.json();
      })
      .catch((err) => err);
  };
  const totalMainCatagories = () => {
    return fetch(`${API}/total-main-catagories`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .catch((err) => err);
  };
  const totalSubCatagories = () => {
    return fetch(`${API}/total-sub-catagories`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .catch((err) => err);
  };
  return {
    addCatagory,
    deleteCatagory,
    updateCatagory,
    viewMainCatagories,
    viewSubCatagories,
    viewAllCatagories,
    companiesList,
    viewAllCatagoriesWithChildren,
    totalMainCatagories,
    totalSubCatagories,
  };
};

export default Catagory;
