import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { Drawer } from "@material-ui/core";
import "./sidebar.css";
import logo from "../../assets/images/logo.png";
import SidebarItem from "./SidebarItem";
import Menu from "../Menu";
import Notify from "../customHelpers/Notify";
import Catagory from "../../actions/Catagory";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import {
  createTheme,
  ListSubheader,
  styled,
  ThemeProvider,
} from "@mui/material";
import Loading from "../customHelpers/Loading";

const FireNav = styled(List)({
  "& .MuiListItemButton-root": {
    paddingLeft: 0,
    paddingRight: 0,
  },
  "& .MuiListItemIcon-root": {
    minWidth: 0,
    marginRight: 16,
  },
  "& .MuiSvgIcon-root": {
    fontSize: 20,
  },
});
const drawerWidth = 270;

const admin_routes = [
  {
    display_name: "Dashboard",
    route: "/admin",
    icon: "bx bxs-dashboard",
  },
  {
    display_name: "companies",
    route: "/admin/company",
    icon: "bx bx-buildings",
  },
  {
    display_name: "manage requestes",
    route: "/admin/requests",
    icon: "bx bx-buildings",
  },
  {
    display_name: "manage call center",
    route: "/admin/callcenter",
    icon: "bx bx-phone",
  },
  {
    display_name: "Catagories",
    route: "/admin/catagories",
    icon: "bx bx-category",
  },
  {
    display_name: "News",
    route: "/admin/news",
    icon: "bx bx-news",
  },
  {
    display_name: "Users",
    route: "/admin/users",
    icon: "bx bx-user-pin",
  },
  {
    display_name: "Ads",
    route: "/admin/ads",
    icon: "bx bx-notepad",
  },
];
const callcenter_routes = [
  {
    display_name: "Search",
    route: "/",
    icon: "bx bx-phone-call",
  },
];
const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },

  drawerPaper: {
    width: drawerWidth,
  },
  borderR: {
    borderRight: "none",
  },
}));

const Sidebar = (props) => {
  const classes = useStyles();
  const { NotifyMessage } = Notify();
  const [expanded, setExpanded] = React.useState("");
  const handleChangeEx = (panel) => {
    setExpanded(expanded != panel ? panel : false);
  };
  const { viewMainCatagories } = Catagory();
  const [values, setValues] = useState({
    loading: false,
    catagories: [],
  });
  const { catagories, loading } = values;
  useEffect(() => {
    getMainCatagories();
  }, []);
  const getMainCatagories = () => {
    setValues({ ...values, loading: true });
    viewMainCatagories().then((data) => {
      if (data.err) {
        NotifyMessage({
          message: data.err,
          type: "error",
        });
      } else if (data.result) {
        setValues({
          catagories: data.result,
          loading: false,
        });
      } else {
        NotifyMessage({
          message: "Network timeout, try again.",
          type: "error",
        });
        setValues({
          ...values,
          loading: false,
        });
      }
    });
  };
  const sidebar_items = props.location.pathname.includes("/admin")
    ? admin_routes
    : callcenter_routes;

  const activeItem = sidebar_items.findIndex(
    (item) => item.route === props.location.pathname
  );

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={props.open}
      classes={{
        paperAnchorDockedLeft: classes.borderR,
        paper: classes.drawerPaper,
      }}
    >
      <div className="sidebar">
        <div className="sidebar__logo">
          <img src={logo} alt="company logo" />
        </div>
        {sidebar_items.map((item, index) => (
          <Link to={item.route} key={index}>
            <SidebarItem
              title={item.display_name}
              icon={item.icon}
              key={index}
              active={index === activeItem}
            />
          </Link>
        ))}
        {
          !props.location.pathname.includes("/admin") && (
            <Box sx={{ display: "flex" }}>
              <ThemeProvider
                theme={createTheme({
                  components: {
                    MuiListItemButton: {
                      defaultProps: {
                        disableTouchRipple: true,
                      },
                    },
                  },
                  palette: {
                    mode: "dark",
                    background: { paper: "rgba(244,151,3,.8)" },
                  },
                })}
              >
                <Paper
                  elevation={0}
                  sx={{
                    width: "100%",
                    mt: 2,
                    borderRadius: "5px",
                  }}
                >
                  <Box
                    sx={{
                      pb: 0,
                    }}
                  >
                    {" "}
                    <FireNav
                      component="nav"
                      subheader={
                        <ListSubheader
                          sx={{
                            color: "white",
                            py: 1,
                            px: 3,
                            borderBottom: "1px solid rgb(20,61,89)",
                          }}
                          component="div"
                        >
                          All Categories
                        </ListSubheader>
                      }
                      sx={{
                        color: "white",
                        p: 0,
                      }}
                    >
                      {" "}
                      {loading ? (
                        <Loading color="secondary" />
                      ) : (
                        catagories.map((catagory) => (
                          <Menu
                            key={catagory.Id}
                            name={catagory.name}
                            sub={catagory.children || []}
                            Id={catagory.Id}
                            handleChangeEx={handleChangeEx}
                            expanded={expanded}
                          />
                        ))
                      )}
                    </FireNav>
                  </Box>
                </Paper>
              </ThemeProvider>
            </Box>
          )
          // (loading ? (
          //   <Loading color="secondary" />
          // ) : (
          //   // catagories.map((catagory) => (
          //   //   <Menu
          //   //     key={catagory.Id}
          //   //     name={catagory.name}
          //   //     sub={catagory.children || []}
          //   //     Id={catagory.Id}
          //   //   />
          //   // ))
          //   <Menu catagories={catagories} />
        }
      </div>
    </Drawer>
  );
};

export default Sidebar;
