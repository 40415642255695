import { withRouter } from "react-router-dom";
import CallCenter from "../../components/auth/CallCenter";
import Companydetails from "../../components/Companydetails";

const CompanyDetailcallcenter = (props) => {
  const company = props.location.state;
  return (
    <CallCenter>
      <Companydetails companyData={company} />
    </CallCenter>
  );
};

export default withRouter(CompanyDetailcallcenter);
