import { Grid, makeStyles } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import Catagory from "../../actions/Catagory";
import Controls from "../controls/Controls";
import { Form, useForm } from "../customHelpers/useForm";

const initialFValues = {
  name: "",
  image: null,
  editing: false,
  parent: "",
  main: "",
  subCatagory1: "",
  subCatagory: "",
};
const useStyles = makeStyles((theme) => ({
  icon: {
    borderRadius: "50%",
    height: "40px",
    width: "40px",
  },
}));
const AddSubCatagory = ({
  NotifyMessage,
  setOpenPopup,
  getAllCatagories,
  recordForEdit,
}) => {
  useEffect(() => {
    if (recordForEdit != null) {
      setValues({
        ...recordForEdit,
        main: recordForEdit.parentId,
        subCatagory1: "",
        subCatagory: "",
        parent: recordForEdit.parentId,
      });
      setfile(recordForEdit.image);
    }
    getCatagories();
  }, [recordForEdit]);
  const classes = useStyles();
  const formData = new FormData();
  const [catagories, setCatagories] = useState([]);
  const [subCatagory, setsubCatagory] = useState([]);
  const [subCatagory1, setsubCatagory1] = useState([]);
  const { addCatagory, updateCatagory, viewSubCatagories, viewMainCatagories } =
    Catagory();
  const roomImagePicker = useRef(null);
  const [file, setfile] = useState(null);
  const validate = (fieldValues = values) => {
    const temp = { ...errors };
    if ("name" in fieldValues)
      temp.name = fieldValues.name.length != 0 ? "" : "This field is required.";
    if ("main" in fieldValues)
      temp.main = fieldValues.main.length != 0 ? "" : "This field is required.";
    if (values.editing == false) {
      if (values.editing == false) {
        if ("image" in fieldValues)
          temp.image =
            fieldValues.image != null ? "" : "This field is required.";
      }
    }

    setErrors({
      ...temp,
    });
    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const getCatagories = () => {
    viewMainCatagories().then((data) => {
      if (data.err) {
        NotifyMessage({
          message: data.err,
          type: "error",
        });
      } else {
        setCatagories(data.result);
      }
    });
  };

  const handleSelectChange = (type) => (e) => {
    const { name, value } = e.target;

    setValues({ ...values, [name]: value, parent: value });
    validate({ [name]: value });
    viewSubCatagories({ Id: value }).then((data) => {
      if (data.err) {
        NotifyMessage({
          message: data.err,
          type: "error",
        });
      } else {
        if (type == "one") {
          setsubCatagory(data.result);
        }
        if (type == "two") {
          setsubCatagory1(data.result);
        }
      }
    });
  };

  const handleSubmit = (e) => {
    formData.append(
      "image",
      values.image instanceof File ? values.image : null
    );
    formData.append("name", values.name);
    formData.append("parent", values.parent);

    e.preventDefault();
    if (validate()) {
      setValues({ ...values, submitting: true });
      if (values.editing == false) {
        addCatagory(formData).then((data) => {
          if (data.err) {
            setValues({ ...values, submitting: false });
            NotifyMessage({
              message: data.err,
              type: "error",
            });
          } else {
            NotifyMessage({
              message: data.message,
              type: "success",
            });
            setOpenPopup(false);
            resetForm();
            getAllCatagories();
          }
        });
      } else {
        updateCatagory(formData, values.Id).then((data) => {
          if (data.err) {
            setValues({ ...values, submitting: false });
            NotifyMessage({
              message: data.err,
              type: "error",
            });
          } else {
            NotifyMessage({
              message: data.message,
              type: "success",
            });
            setOpenPopup(false);
            resetForm();
            getAllCatagories();
          }
        });
      }
    }
  };
  return (
    <Form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={6}>
          <Controls.Input
            name="name"
            label="Name"
            value={values.name}
            onChange={handleInputChange}
            error={errors.name}
          />
        </Grid>
        <Grid item xs={6}>
          <Controls.Select
            name="main"
            label="Catagory"
            value={values.main}
            onChange={handleSelectChange("one")}
            options={catagories}
            error={errors.main}
          />

          {subCatagory.length > 0 ? (
            <Controls.Select
              name="subCatagory"
              label="Sub catagory"
              value={values.subCatagory}
              onChange={handleSelectChange("two")}
              options={subCatagory}
              error={errors.subCatagory}
            />
          ) : (
            ""
          )}
          {subCatagory1.length > 0 ? (
            <Controls.Select
              name="subCatagory1"
              label="Sub catagory"
              value={values.subCatagory1}
              onChange={handleSelectChange("three")}
              options={subCatagory1}
              error={errors.subCatagory1}
            />
          ) : (
            ""
          )}
        </Grid>
        <Grid item xs={6}>
          {values.editing == true ? (
            <div className="flex space-x-3 items-center">
              {" "}
              <img className={classes.icon} src={file} alt={file} />
              <Controls.Button
                color="primary"
                onClick={() => {
                  roomImagePicker.current.click();
                }}
                variant="outlined"
                text="Change image"
                className="newButton"
              />
              <input
                label="Icon"
                name="image"
                type="file"
                hidden
                accept="image/*"
                ref={roomImagePicker}
                onChange={handleInputChange}
                autoFocus={true}
              />
            </div>
          ) : (
            <Controls.Input
              name="image"
              type="file"
              label="Image"
              onChange={handleInputChange}
              error={errors.image}
              autoFocus={true}
            />
          )}
        </Grid>
        <Grid item xs>
          <div>
            <Controls.Button
              disabled={values.submitting ? true : false}
              text={
                values.editing == true
                  ? values.submitting
                    ? "Editing..."
                    : "Edit"
                  : values.submitting
                  ? "Adding..."
                  : "Add"
              }
              variant="contained"
              className="Button"
              type="submit"
            />
          </div>
        </Grid>
      </Grid>
    </Form>
  );
};
export default AddSubCatagory;
