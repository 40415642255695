import { useState } from "react";
import XLSX from "xlsx";

const EXTENSIONS = ["xlsx", "xls", "csv"];
function ImportFile() {
  const [colDefs, setColDefs] = useState([]);
  const [data, setData] = useState([]);

  const getExention = (file) => {
    const parts = file.name.split(".");
    const extension = parts[parts.length - 1];
    return EXTENSIONS.includes(extension);
  };

  const convertToJson = (headers, data) => {
    const rows = [];
    data.forEach((row) => {
      let rowData = {};
      row.forEach((element, index) => {
        rowData[headers[index]] = element;
      });
      rows.push(rowData);
    });
    return rows;
  };

  const importExcel = (e) => {
    const file = e.target.files[0];

    const reader = new FileReader();
    reader.onload = (event) => {
      //parse data

      const bstr = event.target.result;
      const workBook = XLSX.read(bstr, { type: "binary" });

      //get first sheet
      const workSheetName = workBook.SheetNames[0];
      const workSheet = workBook.Sheets[workSheetName];

      //convert to array
      const fileData = XLSX.utils.sheet_to_json(workSheet, { header: 1 });
      const headers = fileData[0].map((head) => {
        return head.trim();
      });
      const heads = headers.map((head) => ({
        id: head.trim(),
        label: head.trim().charAt(0).toUpperCase() + head.trim().slice(1),
      }));
      setColDefs(heads);
    

      //removing header
      fileData.splice(0, 1);

      setData(convertToJson(headers, fileData));
    };

    if (file) {
      if (getExention(file)) {
        reader.readAsBinaryString(file);
      } else {
        alert("Invalid file input, Select Excel, CSV file");
      }
    } else {
      setData([]);
      setColDefs([]);
    }
  };

  return {
    data,
    colDefs,
    importExcel,
    setData,
  };
}

export default ImportFile;
