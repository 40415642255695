import CompanyForm from "./CompanyForm";

import { Typography } from "@material-ui/core";

import ServiceComponent from "../ServiceComponent";
import ContactMethods from "../ContactMethods";

const UpdateCompanyForm = ({
  recordForEdit,
  NotifyMessage,
  setOpenPopup,
  getAllCompanies,
}) => {
  return (
    <>
      <ContactMethods
        NotifyMessage={NotifyMessage}
        PhoneNumber={recordForEdit.PhoneNumbers}
        OfficeNumber={recordForEdit.OfficeNumbers}
        SocialMedia={recordForEdit.SocialMedia}
        Faxes={recordForEdit.Faxes}
        Id={recordForEdit.Id}
      />
      <ServiceComponent NotifyMessage={NotifyMessage} Id={recordForEdit.Id} />
      <div className="card">
        <Typography variant="h5">Company Information</Typography>

        <CompanyForm
          recordForEdit={recordForEdit}
          NotifyMessage={NotifyMessage}
          setOpenPopup={setOpenPopup}
          getAllCompanies={getAllCompanies}
        />
      </div>
    </>
  );
};

export default UpdateCompanyForm;
