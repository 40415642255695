import Admin from "../../components/auth/Admin";
import Layout from "../../components/layout/Layout";
import { TableBody, TableRow, TableCell, Typography } from "@material-ui/core";
import ConfirmDialog from "../../components/customHelpers/ConfirmDialog";
import Popup from "../../components/customHelpers/Popup";
import { IoMdAdd } from "react-icons/io";
import { Grid, Toolbar, InputAdornment, makeStyles } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { MdOutlineDelete } from "react-icons/md";
import { AiOutlineSearch } from "react-icons/ai";
import Controls from "../../components/controls/Controls";
import useTable from "../../components/customHelpers/useTable";
import Norecords from "../../components/customHelpers/Norecords";
import CompanyForm from "../../components/forms/CompanyForm";
import UpdateCompanyForm from "../../components/forms/UpdateCompanyForm";
import Notify from "../../components/customHelpers/Notify";
import Company from "../../actions/Company";
import Notification from "../../components/customHelpers/Notification";
import Loading from "../../components/customHelpers/Loading";
import ImportFile from "../../actions/ImportFile";
import { useHistory } from "react-router-dom";
import SaveFromFile from "../../components/SaveFromFile";
const headCells = [
  { id: "logo", label: "Logo", disableSorting: true },
  { id: "name", label: "Name" },
  { id: "wereda", label: "wereda" },
  { id: "city", label: "City" },
  { id: "SubCity", label: "Sub City" },
  { id: "state", label: "State" },
  { id: "catagory", label: "Catagory" },
  { id: "actions", label: "Actions", disableSorting: true },
];
const makeData = (result) => {
  return result.map((data) => {
    return {
      name: data?.name,
      wereda: data.Address?.wereda || "",
      city: data.Address?.city || "",
      state: data.Address?.state || "",
      subCity: data.Address?.sub_city || "",
      state: data.Address?.state || "",
      kebele: data.Address?.kebele || "",
      street: data.Address?.street_no || "",
      logo: data?.logo,
      description: data.description || "",
      catagoryId: data.catagoryId || "",
      lat: data.Address?.location
        ? data.Address?.location.coordinates[0]
        : null,
      long: data.Address?.location
        ? data.Address?.location.coordinates[1]
        : null,
      Id: data.Id,
      createdAt: data.createdAt,
      catagory: data.Catagory?.name || "",
      web: data.web || "",
      email: data.email || "",
      pobox: data.Address?.pobox || "",
      news: data.News,
      PhoneNumbers: data.PhoneNumbers,
      OfficeNumbers: data.OfficeNumbers,
      Faxes: data.Faxes,
      SocialMedia: data.SocialMedia,
    };
  });
};
const useStyles = makeStyles((theme) => ({
  logo: {
    borderRadius: "50%",
    height: "40px",
    width: "40px",
  },
}));

const Companies = () => {
  const classes = useStyles();
  const history = useHistory();
  const { viewAllCompanies, deleteCompany } = Company();
  const { NotifyMessage, notify, setNotify } = Notify();
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [openPopupBulkSave, setOpenPopupBulkSave] = useState(false);
  const [record, setRecord] = useState({
    data: [],
    colDefs: [],
  });
  const [openPopupUpdate, setOpenPopupUpdate] = useState(false);
  const filePicker = useRef(null);
  const { data, colDefs, importExcel, setData } = ImportFile();
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [values, setValues] = useState({
    loading: false,
    companies: [],
    reload: false,
  });
  const { loading, companies, reload } = values;
  useEffect(() => {
    getAllCompanies();
  }, [reload]);
  const getAllCompanies = () => {
    setValues({
      ...values,
      loading: true,
    });
    viewAllCompanies().then((data) => {
      if (data.err) {
        NotifyMessage({
          message: data.err,
          type: "error",
        });
      } else if (data.result) {
        setValues({
          ...values,
          companies: makeData(data.result),
          loading: false,
        });
      } else {
        NotifyMessage({
          message: "Network time out,try again.",
          type: "error",
        });
        setValues({
          ...values,
          loading: false,
        });
      }
    });
  };

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(
      companies.sort((a, b) =>
        a.createdAt < b.createdAt ? 1 : b.createdAt < a.createdAt ? -1 : 0
      ),
      headCells,
      filterFn
    );
  const openInPopup = (item) => {
    setRecordForEdit({ ...item, editing: true });
    setOpenPopupUpdate(true);
  };

  const onDelete = (Id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    deleteCompany(Id).then((data) => {
      if (data.err) {
        NotifyMessage({
          message: data.err,
          type: "error",
        });
      } else {
        NotifyMessage({
          message: data.message,
          type: "success",
        });
        setValues({ ...values, reload: !reload });
      }
    });
  };
  const handleSearch = (e) => {
    e.target.value = e.target.value.trimLeft();
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        const columns = [
          "name",
          "wereda",
          "city",
          "subCity",
          "catagory",
          "state",
        ];

        if (target.value == "") return items;
        else {
          return items.filter((x) => {
            return columns.some((column) => {
              return (
                x[column]
                  .toString()
                  .toLowerCase()
                  .indexOf(target.value.toLowerCase()) > -1
              );
            });
          });
        }
      },
    });
  };
  if (data && data.length != 0) {
    setRecord({ data, colDefs });

    setData([]);
    setOpenPopupBulkSave(true);
  }

  return (
    <Layout>
      <Admin>
        <div className="card">
          <Typography variant="h6" className="page-header">
            Companies
          </Typography>
          <Toolbar>
            <Controls.Input
              label="Search Companies"
              className="searchInput"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AiOutlineSearch />
                  </InputAdornment>
                ),
              }}
              onChange={handleSearch}
            />

            <Controls.Button
              text="Add New"
              variant="outlined"
              startIcon={<IoMdAdd />}
              className="newButton"
              onClick={() => {
                setOpenPopup(true);
                setRecordForEdit(null);
              }}
            />
            {/* <div>
              <Controls.Button
                color="primary"
                onClick={() => {
                  filePicker.current.click();
                }}
                variant="outlined"
                text="Import file"
                className="newButton"
              />
              <input
                label="Icon"
                name="image"
                type="file"
                hidden
                ref={filePicker}
                onChange={importExcel}
                autoFocus={true}
              />
            </div> */}
          </Toolbar>
          <Grid container>
            <Grid item xs={12} className="card__body">
              {loading ? (
                <Loading />
              ) : (
                <>
                  {" "}
                  <TblContainer>
                    <TblHead />
                    <TableBody>
                      {recordsAfterPagingAndSorting().length > 0 ? (
                        recordsAfterPagingAndSorting().map((item) => (
                          <TableRow key={item.Id}>
                            <TableCell>
                              {item.logo && (
                                <img
                                  className={classes.logo}
                                  src={item.logo}
                                  alt={item.logo}
                                />
                              )}
                            </TableCell>
                            <TableCell
                              style={{ color: "rgb(244,151,3)" }}
                              onClick={() => {
                                history.push({
                                  pathname: `/admin/company/${item.Id}`,
                                  state: item,
                                });
                              }}
                            >
                              {item.name}
                            </TableCell>
                            <TableCell>{item.wereda}</TableCell>
                            <TableCell>{item.city}</TableCell>
                            <TableCell>{item.subCity}</TableCell>
                            <TableCell>{item.state}</TableCell>
                            <TableCell>{item.catagory}</TableCell>

                            <TableCell>
                              <Controls.ActionButton
                                color="primary"
                                title="Update"
                                onClick={() => {
                                  openInPopup(item);
                                }}
                              >
                                <FiEdit fontSize="medium" />
                              </Controls.ActionButton>
                              <Controls.ActionButton
                                color="secondary"
                                title="Delete"
                                onClick={() => {
                                  setConfirmDialog({
                                    isOpen: true,
                                    title:
                                      "Are you sure to delete this company record?",
                                    subTitle: "You can't undo this operation",
                                    onConfirm: () => {
                                      onDelete(item.Id);
                                    },
                                  });
                                }}
                              >
                                <MdOutlineDelete fontSize="medium" />
                              </Controls.ActionButton>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <Norecords col={7} />
                      )}
                    </TableBody>
                  </TblContainer>
                  <TblPagination />
                </>
              )}
            </Grid>
          </Grid>

          <Popup
            title="Add Company"
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
          >
            <CompanyForm
              NotifyMessage={NotifyMessage}
              getAllCompanies={getAllCompanies}
              setOpenPopup={setOpenPopup}
            />
          </Popup>
          <Popup
            title="Update Company"
            openPopup={openPopupUpdate}
            setOpenPopup={setOpenPopupUpdate}
          >
            <UpdateCompanyForm
              recordForEdit={recordForEdit}
              NotifyMessage={NotifyMessage}
              getAllCompanies={getAllCompanies}
              setOpenPopup={setOpenPopupUpdate}
            />
          </Popup>
          <Popup
            title="Import Company"
            openPopup={openPopupBulkSave}
            setOpenPopup={setOpenPopupBulkSave}
            maxWidth={false}
          >
            <SaveFromFile
              file={record}
              NotifyMessage={NotifyMessage}
              setOpenPopupBulkSave={setOpenPopupBulkSave}
              getAllCompanies={getAllCompanies}
            />
          </Popup>
          <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
          />
          <Notification notify={notify} setNotify={setNotify} />
        </div>
      </Admin>
    </Layout>
  );
};
export default Companies;
