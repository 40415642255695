import { Typography, Toolbar, InputAdornment } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import Catagory from "../actions/Catagory";
import CallCenter from "./auth/CallCenter";
import Controls from "./controls/Controls";
import EmptyData from "./customHelpers/EmptyData";
import Loading from "./customHelpers/Loading";
import Notify from "./customHelpers/Notify";
import Layout from "./layout/Layout";
import { AiOutlineSearch } from "react-icons/ai";
const CompanyList = (props) => {
  const { NotifyMessage } = Notify();
  const { companiesList } = Catagory();
  const [Q, setQ] = useState("");
  const [searchColumns, setSearchColumns] = useState(["name"]);
  const [values, setValues] = useState({
    loading: false,
    catagories: {},
  });
  const { loading, catagories } = values;
  const Id = props.match.params.catagoryId;
  useEffect(() => {
    getAllCompanies();
  }, [Id]);
  const getAllCompanies = () => {
    setValues({ ...values, loading: true });

    companiesList(Id).then((data) => {
      if (data.err) {
        NotifyMessage({
          message: data.err,
          type: "error",
        });
      } else if (data.result) {
        setValues({
          catagories: data.result,
          loading: false,
        });
      } else {
        NotifyMessage({
          message: "network timeout, try again.",
          type: "error",
        });
        setValues({
          ...values,
          loading: false,
        });
      }
    });
  };
  const handleSearch = (rows) => {
    return rows.filter((row) =>
      searchColumns.some(
        (column) =>
          row[column].toString().toLowerCase().indexOf(Q.toLowerCase()) > -1
      )
    );
  };
  return (
    <Layout>
      <CallCenter>
        <div className="card">
          <Typography variant="h6" className="page-header">
            Company
          </Typography>
          {catagories.Companies && (
            <Toolbar>
              <Controls.Input
                label="Search Companies"
                className="searchInput"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AiOutlineSearch />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => setQ(e.target.value)}
              />
            </Toolbar>
          )}
          {loading ? (
            <Loading />
          ) : catagories.Companies?.length == 0 ? (
            <EmptyData />
          ) : (
            <div className="row ">
              {catagories.Companies &&
                handleSearch(catagories.Companies).map((item, index) => (
                  <div className="col-6" key={index}>
                    <div className="company-card">
                      {item.logo && (
                        <img
                          src={item.logo}
                          alt={item.logo}
                          
                        />
                      )}
                      <Typography>
                        <span className="company-name">{item.name}</span>
                      </Typography>
                      <Link
                        to={{ pathname: `/company/${item.Id}`, state: item }}
                      >
                        <Controls.Button
                          text=" View detail."
                          variant="outlined"
                        />
                      </Link>
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      </CallCenter>
    </Layout>
  );
};

export default withRouter(CompanyList);
