import Controls from "../controls/Controls";
import { Form, useForm } from "../customHelpers/useForm";
import { Grid, makeStyles } from "@material-ui/core";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { Document, Page, pdfjs } from "react-pdf";
import { useEffect, useRef, useState } from "react";
import Catagory from "../../actions/Catagory";
import Company from "../../actions/Company";
import Popup from "../customHelpers/Popup";
const initialFValues = {
  name: "",
  description: "",
  city: "",
  lat: "",
  long: "",
  street: "",
  subCity: "",
  wereda: "",
  state: "",
  kebele: "",
  catagoryId: "",
  subCatagory: "",
  subCatagory1: "",
  parent: "",
  editing: false,
  image: null,
  email: "",
  web: "",
  pobox: "",
};
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles((theme) => ({
  icon: {
    borderRadius: "50%",
    height: "40px",
    width: "40px",
  },
}));
const CompanyForm = ({
  recordForEdit,
  NotifyMessage,
  setOpenPopup,
  getAllCompanies,
  update,
}) => {
  const classes = useStyles();
  const { viewSubCatagories, viewMainCatagories } = Catagory();

  const { addCompany, updateCompany, adminUpdateUserCompany } = Company();
  const [catagories, setCatagories] = useState([]);
  const roomImagePicker = useRef(null);
  const [file, setfile] = useState(null);
  const [subCatagory, setsubCatagory] = useState([]);
  const [subCatagory1, setsubCatagory1] = useState([]);
  const [readerPopup, setOpenReaderPopup] = useState(false);
  useEffect(() => {
    getMainCatagories();
    if (recordForEdit != null) {
      setValues({
        ...recordForEdit,
        subCatagory: "",
        subCatagory1: "",
        parent: recordForEdit.catagoryId || "",
      });
      setfile(recordForEdit.logo);
    }
  }, [recordForEdit]);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1.0);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }
  const getMainCatagories = () => {
    viewMainCatagories().then((data) => {
      if (data.err) {
        NotifyMessage({
          message: data.err,
          type: "error",
        });
      } else {
        setCatagories(data.result);
      }
    });
  };
  const validate = (fieldValues = values) => {
    const temp = { ...errors };
    const regexEmail =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    // if ("description" in fieldValues)
    //   temp.description =
    //     fieldValues.description.length != 0 ? "" : "This field is required.";
    if ("name" in fieldValues)
      temp.name = fieldValues.name.length != 0 ? "" : "This field is required.";
    // if ("wereda" in fieldValues)
    //   temp.wereda =
    //     fieldValues.wereda.length != 0 ? "" : "This field is required.";
    // if ("city" in fieldValues)
    //   temp.city = fieldValues.city.length != 0 ? "" : "This field is required.";
    // if ("state" in fieldValues)
    //   temp.state =
    //     fieldValues.state.length != 0 ? "" : "This field is required.";
    // if ("lat" in fieldValues)
    //   temp.lat = fieldValues.lat.length != 0 ? "" : "This field is required.";
    // if ("long" in fieldValues)
    //   temp.long = fieldValues.long.length != 0 ? "" : "This field is required.";
    if ("parent" in fieldValues)
      temp.parent =
        fieldValues.parent.length != 0 ? "" : "This field is required.";
    if ("subCatagory" in fieldValues && subCatagory.length > 0)
      temp.subCatagory =
        fieldValues.subCatagory.length != 0 ? "" : "This field is required.";
    if ("subCatagory1" in fieldValues && subCatagory1.length > 0)
      temp.subCatagory1 =
        fieldValues.subCatagory1.length != 0 ? "" : "This field is required.";
    // if (values.editing == false) {
    //   if (values.editing == false) {
    //     if ("image" in fieldValues)
    //       temp.image =
    //         fieldValues.image != null ? "" : "This field is required.";
    //   }
    // }
    if ("email" in fieldValues)
      temp.email =
        !regexEmail.test(fieldValues.email.toString().toLowerCase()) &&
        fieldValues.email.length != 0
          ? "Email is not valid."
          : "";
    setErrors({
      ...temp,
    });
    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSelectChange = (type) => (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value, catagoryId: value });
    validate({ [name]: value });
    setErrors({ ...errors, subCatagory: "", subCatagory1: "" });
    viewSubCatagories({ Id: value }).then((data) => {
      if (data.err) {
        NotifyMessage({
          message: data.err,
          type: "error",
        });
      } else {
        if (type == "one") {
          setsubCatagory(data.result);
        }
        if (type == "two") {
          setsubCatagory1(data.result);
        }
      }
    });
  };
 
  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("description", values.description);
    formData.append("lat", values.lat);
    formData.append("long", values.long);
    formData.append("city", values.city);
    formData.append("subCity", values.subCity);
    formData.append("state", values.state);
    formData.append("street", values.street);
    formData.append("kebele", values.kebele);
    formData.append("wereda", values.wereda);
    formData.append("catagoryId", values.catagoryId);
    formData.append(
      "image",
      values.image instanceof File ? values.image : null
    );
    formData.append("web", values.web);
    formData.append("pobox", values.pobox);
    formData.append("email", values.email);
    if (validate()) {
      setValues({ ...values, submitting: true });
      if (update) {
        adminUpdateUserCompany(formData, values.Id).then((data) => {
          if (data.err) {
            setValues({ ...values, submitting: false });
            NotifyMessage({
              message: data.err,
              type: "error",
            });
          } else {
            NotifyMessage({
              message: data.message,
              type: "success",
            });
            setOpenPopup(false);
            resetForm();
            getAllCompanies();
          }
        });
      } else if (values.editing == false) {
        addCompany(formData).then((data) => {
          if (data.err) {
            setValues({ ...values, submitting: false });
            NotifyMessage({
              message: data.err,
              type: "error",
            });
          } else {
            NotifyMessage({
              message: data.message,
              type: "success",
            });
            setOpenPopup(false);
            resetForm();
            getAllCompanies();
          }
        });
      } else {
        updateCompany(formData, values.Id).then((data) => {
          if (data.err) {
            setValues({ ...values, submitting: false });
            NotifyMessage({
              message: data.err,
              type: "error",
            });
          } else {
            NotifyMessage({
              message: data.message,
              type: "success",
            });
            setOpenPopup(false);
            resetForm();
            getAllCompanies();
          }
        });
      }
    }
  };
  return (
    <Form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={12}>
          <label>Company Information</label>
        </Grid>
        <Grid item xs={6} className="grid-input">
          <Controls.Input
            label="Name"
            name="name"
            value={values.name}
            onChange={handleInputChange}
            error={errors.name}
          />
        </Grid>
        {update && (
          // <Grid item xs={6} className="grid-input">
          <Controls.Button
            onClick={() => {
              setOpenReaderPopup(true);
            }}
            text="View licence"
            variant="contained"
            className="Button"
          />
          // </Grid>
        )}
        <Grid item xs={6} className="grid-input">
          <Controls.Input
            label="Description"
            name="description"
            multiline
            value={values.description}
            onChange={handleInputChange}
            error={errors.description}
          />
        </Grid>
        <Grid item xs={6} className="grid-input">
          <Controls.Input
            label="Email"
            name="email"
            multiline
            value={values.email}
            onChange={handleInputChange}
            error={errors.email}
          />
        </Grid>
        <Grid item xs={6} className="grid-input">
          <Controls.Input
            label="Web"
            name="web"
            multiline
            value={values.web}
            onChange={handleInputChange}
            error={errors.web}
          />
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <label>Logo</label>
          </Grid>
          <Grid item xs={6}>
            {values.editing == true ? (
              <div className="flex space-x-3 items-center">
                {" "}
                {file && <img className={classes.icon} src={file} alt={file} />}
                <Controls.Button
                  color="primary"
                  onClick={() => {
                    roomImagePicker.current.click();
                  }}
                  variant="outlined"
                  text="Change Logo"
                  className="newButton"
                />
                <input
                  label="Logo"
                  name="image"
                  type="file"
                  hidden
                  accept="image/*"
                  ref={roomImagePicker}
                  onChange={handleInputChange}
                  autoFocus={true}
                />
              </div>
            ) : (
              <Controls.Input
                name="image"
                type="file"
                onChange={handleInputChange}
                error={errors.image}
              />
            )}
          </Grid>
          <Grid item xs={6}>
            <Controls.Select
              name="parent"
              label="Catagory"
              value={values.parent}
              onChange={handleSelectChange("one")}
              options={catagories}
              error={errors.parent}
            />

            {subCatagory.length > 0 ? (
              <Controls.Select
                name="subCatagory"
                label="Sub catagory"
                value={values.subCatagory}
                onChange={handleSelectChange("two")}
                options={subCatagory}
                error={errors.subCatagory}
              />
            ) : (
              ""
            )}
            {subCatagory1.length > 0 ? (
              <Controls.Select
                name="subCatagory1"
                label="Sub catagory"
                value={values.subCatagory1}
                onChange={handleSelectChange("three")}
                options={subCatagory1}
                error={errors.subCatagory1}
              />
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <label>Address</label>
        </Grid>

        <Grid item xs={6}>
          <Controls.Input
            label="Wereda"
            name="wereda"
            value={values.wereda}
            onChange={handleInputChange}
            error={errors.wereda}
          />
        </Grid>
        <Grid item xs={6} className="grid-input">
          <Controls.Input
            label="City"
            name="city"
            value={values.city}
            onChange={handleInputChange}
            error={errors.city}
          />
        </Grid>
        <Grid item xs={6}>
          <Controls.Input
            label="Sub city"
            name="subCity"
            value={values.subCity}
            onChange={handleInputChange}
            error={errors.subCity}
          />
        </Grid>
        <Grid item xs={6}>
          <Controls.Input
            label="State"
            name="state"
            value={values.state}
            onChange={handleInputChange}
            error={errors.state}
          />
        </Grid>
        <Grid item xs={6}>
          <Controls.Input
            label="kebele"
            name="kebele"
            value={values.kebele}
            onChange={handleInputChange}
            error={errors.kebele}
          />
        </Grid>
        <Grid item xs={6}>
          <Controls.Input
            label="Street"
            name="street"
            value={values.street}
            onChange={handleInputChange}
            error={errors.street}
          />
        </Grid>

        <Grid item xs={6}>
          <Controls.Input
            label="P.o.Box"
            name="pobox"
            value={values.pobox}
            onChange={handleInputChange}
            error={errors.pobox}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <label>Coordinates</label>
        </Grid>

        <Grid item xs={6}>
          <Controls.Input
            label="Lat"
            name="lat"
            value={values.lat}
            onChange={handleInputChange}
            error={errors.lat}
          />
        </Grid>
        <Grid item xs={6}>
          <Controls.Input
            label="Long"
            name="long"
            value={values.long}
            onChange={handleInputChange}
            error={errors.long}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Controls.Button
          disabled={values.submitting ? true : false}
          text={
            values.editing == true
              ? values.submitting
                ? "Editing..."
                : "Edit"
              : values.submitting
              ? "Adding..."
              : "Add"
          }
          variant="contained"
          className="Button"
          type="submit"
        />
      </Grid>
      <Popup
        title="Licence"
        openPopup={readerPopup}
        setOpenPopup={setOpenReaderPopup}
      >
        <Document
          style={{ border: "5px solid black" }}
          file={values.licence}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page pageNumber={pageNumber} scale={scale} />
        </Document>{" "}
      </Popup>
    </Form>
  );
};

export default CompanyForm;
