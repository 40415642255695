import Layout from "../../components/layout/Layout";
import CallCenter from "../../components/auth/CallCenter";
import Catagory from "../../actions/Catagory";
import { useEffect, useState } from "react";
import Notify from "../../components/customHelpers/Notify";
import StatusCard from "../../components/status-card/StatusCard";
import { Typography } from "@material-ui/core";
import Loading from "../../components/customHelpers/Loading";
import EmptyData from "../../components/customHelpers/EmptyData";
const Index = () => {
  const { NotifyMessage } = Notify();
  const { viewMainCatagories } = Catagory();
  const [values, setValues] = useState({
    loading: false,
    catagories: [],
    reload: false,
  });
  const { loading, catagories, reload } = values;
  useEffect(() => { 
    getMainCatagories();
  }, [reload]);
  const getMainCatagories = () => {
    setValues({ ...values, catagories: [], loading: true, error: false });
    viewMainCatagories().then((data) => {
      if (data.err) {
        NotifyMessage({
          message: data.err,
          type: "error",
        });
      } else {
        setValues({
          ...values,
          catagories: data.result,
          loading: false,
        });
      }
    });
  };
  return (
    <Layout>
      <CallCenter>
        <div className="card">
          <Typography variant="h6" className="page-header">
            Catagories
          </Typography>
          {loading ? (
            <Loading />
          ) : (
            <div className="row">
              {catagories.length == 0 ? (
                <EmptyData />
              ) : (
                catagories.map((item, index) => (
                  <div className="col-4" key={index}>
                    <StatusCard item={item} />
                  </div>
                ))
              )}
            </div>
          )}
        </div>
      </CallCenter>
    </Layout>
  );
};

export default Index;
