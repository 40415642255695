import { TableBody, TableCell, TableRow } from "@material-ui/core";
import { useState } from "react";
import Company from "../actions/Company";
import Controls from "./controls/Controls";
import useTable from "./customHelpers/useTable";

const SaveFromFile = (props) => {
  const { addCompanyFromFile, addCompanyForcatagory } = Company();
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(
      props.file.data.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      ),
      props.file.colDefs,
      filterFn
    );
  const handleSubmit = () => {
    if (props.Id) { 
      addCompanyForcatagory(props.file?.data, props.Id).then((data) => {
        if (data.err) {
          props.NotifyMessage({
            message: data.err,
            type: "error",
          });
        } else {
          props.NotifyMessage({
            message: data.message,
            type: "success",
          });
          props.setOpenPopupBulkSave(false);
        }
      });
    } else {
      addCompanyFromFile(props.file?.data).then((data) => {
        if (data.err) {
          props.NotifyMessage({
            message: data.err,
            type: "error",
          });
        } else {
          props.NotifyMessage({
            message: data.message,
            type: "success",
          });
          props.setOpenPopupBulkSave(false);
          props.getAllCompanies();
        }
      });
    }
  };
  return (
    <div>
      <TblContainer>
        <TblHead />
        <TableBody>
          {recordsAfterPagingAndSorting().map((item, index) => (
            <TableRow key={index}>
              {props.file.colDefs.map((col, index) => {
                return (
                  <TableCell key={index}>
                    {item[col.id]
                      ? item[col.id].length > 10
                        ? item[col.id].substring(0, 10) + "..."
                        : item[col.id]
                      : null}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </TblContainer>
      <TblPagination />
      <Controls.Button text="Submit" onClick={handleSubmit} />
    </div>
  );
};

export default SaveFromFile;
