import {
  Grid,
  InputAdornment,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { IoMdAdd } from "react-icons/io";
import Admin from "../../components/auth/Admin";
import Controls from "../../components/controls/Controls";
import Notify from "../../components/customHelpers/Notify";
import Popup from "../../components/customHelpers/Popup";
import NewsForm from "../../components/forms/NewsForm";
import Layout from "../../components/layout/Layout";
import News from "../../actions/News";
import useTable from "../../components/customHelpers/useTable";
import { MdOutlineDelete } from "react-icons/md";
import { FiEdit, FiEye } from "react-icons/fi";
import Loading from "../../components/customHelpers/Loading";
import Norecords from "../../components/customHelpers/Norecords";
import Notification from "../../components/customHelpers/Notification";
import ConfirmDialog from "../../components/customHelpers/ConfirmDialog";
import ViewNews from "../../components/ViewNews";
const headCells = [
  { id: "title", label: "Title" },
  { id: "author", label: "Author" },
  { id: "createdAt", label: "Created At" },
  { id: "actions", label: "Actions", disableSorting: true },
];
const makeData = (result) => {
  return result.map((data) => {
    return {
      title: data.title,
      Id: data.Id,
      body: data.body,
      image: data.headingImage,
      author: data.author,
      createdAt: data.createdAt,
    };
  });
};
const ManageNews = () => {
  const { deleteNews, getNews } = News();
  const [openPopup, setOpenPopup] = useState(false);
  const [openPopupNews, setOpenPopupNews] = useState(false);
  const { NotifyMessage, notify, setNotify } = Notify();
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [newsToView, setNews] = useState(null);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [values, setValues] = useState({
    loading: false,
    news: [],
    reload: false,
  });
  const { loading, news, reload } = values;
  useEffect(() => {
    getAllNews();
  }, [reload]);
  const getAllNews = () => {
    setValues({
      ...values,
      loading: true,
      error: false,
    });
    getNews().then((data) => {
      if (data.err) {
        NotifyMessage({
          message: data.err,
          type: "error",
        });
      } else {
        setValues({
          ...values,
          news: makeData(data.result),
          loading: false,
        });
      }
    });
  };

  const handleSearch = (e) => {
    e.target.value = e.target.value.trimLeft();
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        const columns = ["title", "author"];

        if (target.value == "") return items;
        else {
          return items.filter((x) => {
            return columns.some((column) => {
              return (
                x[column]
                  .toString()
                  .toLowerCase()
                  .indexOf(target.value.toLowerCase()) > -1
              );
            });
          });
        }
      },
    });
  };
  const openInPopup = (item) => {
    setRecordForEdit({ ...item, editing: true });
    setOpenPopup(true);
  };
  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(
      news.sort((a, b) =>
        a.createdAt < b.createdAt ? 1 : b.createdAt < a.createdAt ? -1 : 0
      ),
      headCells,
      filterFn
    );

  const onDelete = (Id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    deleteNews(Id).then((data) => {
      if (data.err) {
        NotifyMessage({
          message: data.err,
          type: "error",
        });
      } else {
        NotifyMessage({
          message: data.message,
          type: "success",
        });
        setValues({ ...values, reload: !reload });
      }
    });
  };
  return (
    <Layout>
      <Admin>
        <div className="card">
          <Typography variant="h6" className="page-header">
            News
          </Typography>
          <Toolbar>
            <Controls.Input
              label="Search News"
              className="searchInput"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AiOutlineSearch />
                  </InputAdornment>
                ),
              }}
              onChange={handleSearch}
            />

            <Controls.Button
              text="Add New"
              variant="outlined"
              startIcon={<IoMdAdd />}
              className="newButton"
              onClick={() => {
                setOpenPopup(true);
                setRecordForEdit(null);
              }}
            />
          </Toolbar>
          <Grid container>
            <Grid item xs={12} className="card__body">
              {loading ? (
                <Loading />
              ) : (
                <>
                  {" "}
                  <TblContainer>
                    <TblHead />
                    <TableBody>
                      {recordsAfterPagingAndSorting().length > 0 ? (
                        recordsAfterPagingAndSorting().map((item) => (
                          <TableRow key={item.Id}>
                            <TableCell>{item.title}</TableCell>
                            <TableCell>{item.author}</TableCell>
                            <TableCell>
                              {new Date(item.createdAt).toLocaleString(
                                "en-US",
                                { hour12: true }
                              )}
                            </TableCell>

                            <TableCell>
                              <Controls.ActionButton
                                color="primary"
                                title="Update"
                                onClick={() => {
                                  openInPopup(item);
                                }}
                              >
                                <FiEdit fontSize="medium" />
                              </Controls.ActionButton>
                              <Controls.ActionButton
                                color="secondary"
                                title="Delete"
                                onClick={() => {
                                  setConfirmDialog({
                                    isOpen: true,
                                    title: "Are you sure to delete this news?",
                                    subTitle: "You can't undo this operation",
                                    onConfirm: () => {
                                      onDelete(item.Id);
                                    },
                                  });
                                }}
                              >
                                <MdOutlineDelete fontSize="medium" />
                              </Controls.ActionButton>
                              <Controls.ActionButton
                                color="primary"
                                title="View"
                                onClick={() => {
                                  setNews(item);
                                  setOpenPopupNews(true);
                                }}
                              >
                                <FiEye fontSize="medium" />
                              </Controls.ActionButton>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <Norecords col={4} />
                      )}
                    </TableBody>
                  </TblContainer>
                  <TblPagination />
                </>
              )}
            </Grid>
          </Grid>
          <Popup
            title="News Form"
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
          >
            <NewsForm
              recordForEdit={recordForEdit}
              NotifyMessage={NotifyMessage}
              getAllNews={getAllNews}
              setOpenPopup={setOpenPopup}
            />
          </Popup>
          <Popup
            title="News"
            openPopup={openPopupNews}
            setOpenPopup={setOpenPopupNews}
            scroll="body"
          >
            <ViewNews newsToView={newsToView} />
          </Popup>
          <Notification notify={notify} setNotify={setNotify} />
          <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
          />
        </div>
      </Admin>
    </Layout>
  );
};

export default ManageNews;
