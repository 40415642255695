import {
  Grid,
  InputAdornment,
  Divider,
  TextField,
  Typography,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { Link } from "react-router-dom";
import Company from "../../actions/Company";
import CallCenter from "../../components/auth/CallCenter";
import Controls from "../../components/controls/Controls";
import Notification from "../../components/customHelpers/Notification";
import Loading from "../../components/customHelpers/Loading";
import Notify from "../../components/customHelpers/Notify";
import Layout from "../../components/layout/Layout";
import { useHistory } from "react-router-dom";
import { isAuth } from "../../actions/auth";
import { Autocomplete } from "@material-ui/lab";

const makeData = (result) => {
  return result.map((data) => {
    return {
      name: data?.name,
      wereda: data.Address?.wereda,
      city: data.Address?.city,
      state: data.Address?.state,
      subCity: data.Address?.sub_city,
      state: data.Address?.state,
      kebele: data.Address?.kebele,
      street: data.Address?.street_no,
      logo: data?.logo,
      description: data.description,
      catagoryId: data.catagoryId,

      lat: data.Address?.location
        ? data.Address?.location.coordinates[0]
        : null,
      long: data.Address?.location
        ? data.Address?.location.coordinates[1]
        : null,
      Id: data.Id,
      createdAt: data.createdAt,
    };
  });
};
const SearchCompany = () => {
  const history = useHistory();
  const { viewAllCompanies, viewSearchHistory, addSearchHistory } = Company();
  const { NotifyMessage, notify, setNotify } = Notify();
  const [values, setValues] = useState({
    loading: false,
    companies: [],
  });
  const [AC, setAC] = useState(null);
  const [histories, setHistories] = useState({
    value: [],
    historyLoading: false,
  });
  const { loading, companies } = values;
  const [Q, setQ] = useState("");
  const [searchColumns, setSearchColumns] = useState(["name"]);

  useEffect(() => {
    getAllCompanies();
    getSearchHistory();
  }, [history]);

  const getAllCompanies = () => {
    setValues({
      ...values,
      loading: true,
    });
    viewAllCompanies().then((data) => {
      if (data.err) {
        NotifyMessage({
          message: data.err,
          type: "error",
        });
      } else if (data.result) {
        setValues({
          ...values,
          companies: makeData(data.result),
          loading: false,
        });
      } else {
        NotifyMessage({
          message: "Network timeout, try again.",
          type: "error",
        });
        setValues({
          ...values,
          loading: false,
        });
      }
    });
  };

  const getSearchHistory = () => {
    setHistories({ ...histories, historyLoading: true });
    const Id = isAuth() && isAuth().Id;
    viewSearchHistory(Id).then((data) => {
      if (data.err) {
        NotifyMessage({
          message: data.err,
          type: "error",
        });
      } else if (data.result) {
        setHistories({
          value: data.result,
          historyLoading: false,
        });
      } else {
        NotifyMessage({
          message: "Network timeout, try again.",
          type: "error",
        });
        setHistories({
          ...histories,
          loading: false,
        });
      }
    });
  };

  const handleSearch = (rows) => {
    return rows.filter((row) =>
      searchColumns.some(
        (column) =>
          row[column].toString().toLowerCase().indexOf(Q.toLowerCase()) > -1
      )
    );
  };
  const saveRecent = (Id) => {
    const data = { companyId: Id, callCenterId: isAuth() && isAuth().Id };
    addSearchHistory(data).then((data) => {
      if (data.err) {
        console.log(data.err);
      } else {
        console.log(data);
      }
    });
  };

  return (
    <Layout>
      <CallCenter>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <div className="card">
              <Controls.Input
                label="Search Companies"
                className="searchInput"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AiOutlineSearch />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => setQ(e.target.value)}
              />
              {/* <Autocomplete
                options={companies}
                getOptionLabel={(option) => option.name}
                className="searchInput"
                value={AC}
                onChange={(event, newValue) => {
                  setAC(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search Companies"
                    variant="outlined"
                  />
                )}
              /> */}
              <div className="row">
                {Q === ""
                  ? histories.value.length > 0 && (
                      <div className="col-12">
                        <Link
                          to={{
                            pathname: `/company/${histories.value[0].Company?.Id}`,
                            state: histories.value[0].Company,
                          }}
                        >
                          <div className="search-card ">
                            <Typography variant="h5">
                              {" "}
                              {histories.value[0].Company?.name
                                ?.charAt(0)
                                .toUpperCase() +
                                histories.value[0].Company?.name.slice(1)}
                            </Typography>
                            <label>
                              {histories.value[0].Company?.description &&
                              histories.value[0].Company?.description.length >
                                200
                                ? histories.value[0].Company?.description.substring(
                                    0,
                                    200
                                  ) + "..."
                                : histories.value[0].Company?.description}
                            </label>
                          </div>
                        </Link>
                      </div>
                    )
                  : handleSearch(companies).map((company) => {
                      return (
                        <div className="col-12" key={company.Id}>
                          <Link
                            onClick={() => saveRecent(company.Id)}
                            to={{
                              pathname: `/company/${company.Id}`,
                              state: company,
                            }}
                          >
                            <div className="search-card ">
                              <span>
                                {" "}
                                {company.name.charAt(0).toUpperCase() +
                                  company.name.slice(1)}
                              </span>
                              <label>
                                {company.description &&
                                company.description.length > 200
                                  ? company.description.substring(0, 200) +
                                    "..."
                                  : company.description}
                              </label>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
              </div>
            </div>
          </Grid>
          <Grid item xs>
            <div className="card">
              <label>Search history</label> <Divider />
              <div className="row">
                <div className="col-12">
                  {histories.historyLoading ? (
                    <Loading />
                  ) : (
                    histories.value.map((history) => {
                      return (
                        <Link
                          to={{
                            pathname: `/company/${history.Company?.Id}`,
                            state: history.Company,
                          }}
                          key={history.Id}
                        >
                          <div className="search__history">
                            <Typography>
                              {" "}
                              {history.Company?.name.charAt(0).toUpperCase() +
                                history.Company?.name.slice(1)}
                            </Typography>
                          </div>
                        </Link>
                      );
                    })
                  )}
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
        <Notification notify={notify} setNotify={setNotify} />
      </CallCenter>
    </Layout>
  );
};

export default SearchCompany;
