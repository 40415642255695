import { Typography } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import Controls from "../controls/Controls";
import "./statuscard.css";

const StatusCard = (props) => {
  const endpoint =
    props.item.children.length == 0
      ? `/companylist/${props.item.Id}`
      : `/subcatagory/${props.item.Id}`;

  return (
    <div className="status-card">
      <div className="status-card__icon">
        <img
          className="CompanyImg"
          src={props.item.image}
          alt={props.item.image}
        />
      </div>
      <div className="status-card__info">
        <Typography variant="h5">{props.item.name}</Typography>
        <span>
          <Link to={endpoint}>
            <Controls.Button text=" View more." variant="outlined" />
          </Link>
        </span>
      </div>
    </div>
  );
};

export default StatusCard;
