import fetch from "isomorphic-fetch";
import { useHistory } from "react-router-dom";
import { API } from "../config";

import { getCookie, userSessionExpired } from "./auth";
const ContactList = () => {
  const history = useHistory();

  const addContactMethodRecord = (values,Id) => {
    return fetch(`${API}/add-contact-method/${Id}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("token")}`,
      },
      body: JSON.stringify(values),
    })
      .then((response) => {
        userSessionExpired(response, history);
        return response.json();
      })
      .catch((err) => err);
  };

  const deleteContactMethodRecord = (method, Id) => {
    const data=`${method}|${Id}`
    return fetch(`${API}/delete-contact-method/${data}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("token")}`,
      },
    })
      .then((response) => {
        userSessionExpired(response, history);
        return response.json();
      })
      .catch((err) => err);
  };

  const updateContactMethodRecord = (values) => {
    return fetch(`${API}/modify-contact-method/${values.Id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("token")}`,
      },
      body: JSON.stringify(values),
    })
      .then((response) => {
        userSessionExpired(response, history);
        return response.json();
      })
      .catch((err) => err);
  };
  const viewContactmethods = (Id) => {
    return fetch(`${API}/view-contact-methods/${Id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("token")}`,
      },
    })
      .then((response) => {
        userSessionExpired(response, history);
        return response.json();
      })
      .catch((err) => err);
  };
  return {
    updateContactMethodRecord,
    viewContactmethods,
    deleteContactMethodRecord,
    addContactMethodRecord,
  };
};

export default ContactList;
