import { withRouter } from "react-router-dom";
import Admin from "../../components/auth/Admin";
import Companydetails from "../../components/Companydetails";

const CompanyDetail = (props) => {
  const company = props.location.state;

  return (
    <Admin>
      <Companydetails companyData={company} />
    </Admin>
  );
};

export default withRouter(CompanyDetail);
