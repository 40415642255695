import {
  InputLabel,
  Typography,
  TextField,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import "mapbox-gl/dist/mapbox-gl.css";
import ReactMapGL, {
  Marker,
  Popup,
  NavigationControl,
  FlyToInterpolator,
} from "react-map-gl";
import { FaFax } from "react-icons/fa";
import { CgWebsite } from "react-icons/cg";
import { GiRotaryPhone } from "react-icons/gi";
import { IoMegaphoneOutline } from "react-icons/io5";
import { BsTelephone } from "react-icons/bs";
import { useEffect, useState } from "react";
import Loading from "./customHelpers/Loading";
import Layout from "./layout/Layout";
import { useHistory } from "react-router-dom";
import Mappin from "../assets/images/Mappin.svg";
import moment from "moment";
function slugify(str) {
  str = str.replace(/^\s+|\s+$/g, "");

  // Make the string lowercase
  str = str.toLowerCase();

  // Remove accents, swap ñ for n, etc
  var from =
    "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;";
  var to =
    "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------";
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  // Remove invalid chars
  str = str
    .replace(/[^a-z0-9 -]/g, "")
    // Collapse whitespace and replace by -
    .replace(/\s+/g, "-")
    // Collapse dashes
    .replace(/-+/g, "-");

  return str;
}
const useStyles = makeStyles((theme) => ({
  companyLogo: {
    width: "100px",
    height: "100px",
    borderRadius: "10px",
    objectFit: "cover",
    marginRight: "20px",
  },

  companyName: {
    fontWeight: "600",
    textTransform: "capitalize",
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  companyDescription: {
    borderBottom: "1px solid rgba(229, 231, 235,1)",
    width: "768px",
    margin: "10px auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const Companydetails = ({ companyData }) => {
  let history = useHistory();
  const classes = useStyles();
  const [showPopup, togglePopup] = useState(false);
  const [viewport, setViewport] = useState({
    width: "100%",
    height: 500,
    zoom: 14,
  });
  const goToCL = () => {
    setViewport({
      ...viewport,
      longitude: marker.longitude,
      latitude: marker.latitude,
      zoom: 14,
      transitionDuration: 5000,
      transitionInterpolator: new FlyToInterpolator(),
    });
  };
  const [marker, setMarker] = useState({
    latitude: 0,
    longitude: 0,
  });
  const [values, setValues] = useState({
    loading: false,
    company: {},
  });
  const { loading, company } = values;
  useEffect(() => {
    getCompanyDetails();
  }, [companyData]);
  const getCompanyDetails = () => {
    setValues({ ...values, loading: true });
    const data = { result: companyData };

    setMarker({
      longitude: data.result.long ? data.result.long : null,
      latitude: data.result.lat ? data.result.lat : null,
    });
    setViewport({
      ...viewport,
      longitude: data.result.long ? data.result.long : null,
      latitude: data.result.lat ? data.result.lat : null,
    });
    setValues({
      company: data.result,
      loading: false,
    });
  };

  return (
    <Layout>
      <div className="card ">
        <Typography variant="h6" className="page-header">
          <IconButton onClick={() => history.goBack()}>
            <i className="bx bx-arrow-back"></i>
          </IconButton>
          Company Details
        </Typography>
        {loading ? (
          <Loading />
        ) : (
          <div className={`${classes.wrapper} relative`}>
            <div className="flex items-center justify-center">
              {company.logo && (
                <img
                  className={classes.companyLogo}
                  src={company.logo}
                  alt={company.logo}
                />
              )}
              <Typography className={classes.companyName} variant="h4">
                {company.name}
              </Typography>
            </div>
            <div className={classes.companyDescription}>
              <label>{company.description}</label>
            </div>

            <div className="Info ">
              <div>
                <Typography variant="h6">Address</Typography>
                {company?.state && (
                  <div className="row">
                    <div className="col-6">
                      <InputLabel style={{ color: "#455560" }}>
                        State :
                      </InputLabel>
                    </div>
                    <div className="col-6">
                      <TextField value={company?.state} />
                    </div>
                  </div>
                )}
                {company?.city && (
                  <div className="row">
                    <div className="col-6">
                      <InputLabel style={{ color: "#455560" }}>
                        City :
                      </InputLabel>
                    </div>
                    <div className="col-6">
                      <TextField value={company?.city} />
                    </div>
                  </div>
                )}
                {company?.wereda && (
                  <div className="row">
                    <div className="col-6">
                      <InputLabel style={{ color: "#455560" }}>
                        Wereda :
                      </InputLabel>
                    </div>
                    <div className="col-6">
                      <TextField value={company?.wereda} />
                    </div>
                  </div>
                )}
                {company?.subCity && (
                  <div className="row">
                    <div className="col-6">
                      <InputLabel style={{ color: "#455560" }}>
                        Sub city :
                      </InputLabel>
                    </div>
                    <div className="col-6">
                      <TextField value={company?.subCity} />
                    </div>
                  </div>
                )}
                {company?.street && (
                  <div className="row">
                    <div className="col-6">
                      <InputLabel style={{ color: "#455560" }}>
                        Street number :
                      </InputLabel>
                    </div>
                    <div className="col-6">
                      <TextField value={company?.street} />
                    </div>
                  </div>
                )}
                {company?.kebele && (
                  <div className="row">
                    <div className="col-6">
                      <InputLabel style={{ color: "#455560" }}>
                        Kebele :
                      </InputLabel>
                    </div>
                    <div className="col-6">
                      <TextField value={company?.kebele} />
                    </div>
                  </div>
                )}
                {company?.pobox && (
                  <div className="row">
                    <div className="col-6">
                      <InputLabel style={{ color: "#455560" }}>
                        P.o.box :
                      </InputLabel>
                    </div>
                    <div className="col-6">
                      <TextField value={company?.pobox} />
                    </div>
                  </div>
                )}
              </div>
              <div>
                <Typography variant="h6">Contact</Typography>
                {company.PhoneNumbers?.length > 0 && (
                  <div className="row">
                    <div className="col-6">
                      <InputLabel style={{ color: "#455560" }}>
                        Phone Numbers:
                      </InputLabel>
                    </div>
                    <div className="col-6">
                      {company.PhoneNumbers.map((number) => (
                        <div className="flex justify-center items-center space-x-3 social__media__icon">
                          {" "}
                          <BsTelephone />
                          <TextField value={number.phone_no} />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {company.OfficeNumbers?.length > 0 && (
                  <div className="row">
                    <div className="col-6">
                      <InputLabel style={{ color: "#455560" }}>
                        Office Numbers:
                      </InputLabel>
                    </div>
                    <div className="col-6">
                      {company.OfficeNumbers.map((number) => (
                        <div className="flex justify-center items-center space-x-3 social__media__icon">
                          {" "}
                          <GiRotaryPhone />
                          <TextField value={number.office_no} />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {company.Faxes?.length > 0 && (
                  <div className="row">
                    <div className="col-6">
                      <InputLabel style={{ color: "#455560" }}>
                        Fax Numbers:
                      </InputLabel>
                    </div>
                    <div className="col-6">
                      {company.Faxes.map((number) => (
                        <div className="flex justify-center items-center space-x-3 social__media__icon">
                          {" "}
                          <FaFax />
                          <TextField value={number.fax} />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {company.SocialMedia?.length > 0 && (
                  <div className="row">
                    <div className="col-6">
                      <InputLabel style={{ color: "#455560" }}>
                        Social Medias:
                      </InputLabel>
                    </div>
                    <div className="col-6">
                      {company.SocialMedia.map((account) => (
                        <div className="flex justify-center items-center space-x-3 social__media__icon">
                          <IoMegaphoneOutline />
                          <TextField value={account.social_media} />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {company.web && (
                  <div className="row">
                    <div className="col-6">
                      <InputLabel style={{ color: "#455560" }}>
                        Web :
                      </InputLabel>
                    </div>
                    <div className="col-6">
                      <div className="flex justify-center items-center space-x-3 social__media__icon">
                        <CgWebsite />
                        <TextField value={company.web} />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {company.long && company.lat && (
              <>
                <ReactMapGL
                  {...viewport}
                  mapStyle="mapbox://styles/mapbox/streets-v11"
                  mapboxApiAccessToken="pk.eyJ1IjoicmNuZGMiLCJhIjoiY2t3MHFieXoxMTFpYTJwbm9oODVzemJ2dyJ9.gtaXKtU2le1TZZ0GYa_FJQ"
                  onViewportChange={(viewport) => setViewport(viewport)}
                >
                  {" "}
                  <NavigationControl
                    className="navigation-control"
                    showCompass={false}
                  />
                  <Marker {...marker}>
                    <button
                      className="marker-btn"
                      onClick={() => togglePopup((prev) => !prev)}
                    >
                      <img src={Mappin} />
                      {company.name}
                    </button>
                  </Marker>
                  {showPopup && (
                    <Popup
                      {...marker}
                      closeButton={false}
                      onClose={() => togglePopup(false)}
                    >
                      <div className="map__content">
                        <img
                          height={150}
                          width={150}
                          src={company.logo}
                          alt={company.logo}
                        />
                        <strong
                          style={{
                            color: "rgb(20, 61, 89)",
                            textTransform: "uppercase",
                          }}
                        >
                          {" "}
                          {company.name}
                        </strong>
                        <div>
                          {" "}
                          {company.description.length > 20
                            ? company.description.substring(0, 20) + "..."
                            : company.description}
                        </div>
                      </div>
                    </Popup>
                  )}
                </ReactMapGL>
                <button onClick={goToCL} className="current__location">
                  <i className="bx-sm bx bx-current-location"></i>
                </button>
              </>
            )}
            {company.news && (
              <div className="info">
                
                <div className="news__list__wrapper">
                  {company.news.map((item, index) => {
                    return (
                      <div
                        onClick={() => {
                          history.push({
                            pathname: `/news/${slugify(item.title)}`,
                            state: { item },
                          });
                        }}
                        style={{ cursor: "pointer" }}
                        key={index}
                        className="companyGrid"
                      >
                        <div className="news__body">
                          {" "}
                          <div className="imageWrapper">
                            <img
                              src={item.headingImage}
                              alt={item.headingImage}
                              fluid
                              className="imageGrid"
                            />
                          </div>
                          <Typography style={{ color: "rgb(20, 61, 89)" }}>
                            {item.title.charAt(0).toUpperCase() +
                              item.title.slice(1)}
                          </Typography>
                        </div>
                        <div className="bottomNews">
                          <p style={{ margin: "10px 0px" }}>
                            Written by{" "}
                            <span
                              style={{
                                fontSize: "14px",
                                fontWeight: "600",
                                color: "rgb(151, 150, 150)",
                              }}
                            >
                              {item.author}
                            </span>{" "}
                            <span> | </span>
                            <span
                              style={{
                                fontSize: "14px",
                                fontWeight: "600",
                                color: "rgb(151, 150, 150)",
                              }}
                            >
                              {moment(item.createdAt).fromNow()}
                            </span>
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Companydetails;
