import { Grid, Tooltip } from "@material-ui/core";
import { useEffect } from "react";
import ContactList from "../../actions/ContactList";
import Controls from "../controls/Controls";
import { Form } from "../customHelpers/useForm";

const AddNewContactForm = ({ data }) => {
  const {
    values,
    Id,
    validate,
    Field,
    handleInputChange,
    resetForm,
    setField,
    NotifyMessage,
    setValues,
    getAllContactmethods,
    errors,
    initialFValues,
    setOpenPopup,
  } = data;
  const field = Object.keys(Field).length !== 0 ? Field : values.object;

  const { addContactMethodRecord, updateContactMethodRecord } = ContactList();

  const handleLogoClick = (e) => {
    e.target.parentNode.childNodes.forEach((child) => {
      if (child.classList) child.classList.remove("active");
      e.target.classList.add("active");
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
        setValues({ ...values, submitting: true });
      if (values.editing == false) {
        addContactMethodRecord(values, Id).then((data) => {
          if (data.err) {
              setValues({ ...values, submitting: false });
            NotifyMessage({
              message: data.err,
              type: "error",
            });
          } else {
            NotifyMessage({
              message: data.message,
              type: "success",
            });
            getAllContactmethods();
            setOpenPopup(false);
            setField({});
            resetForm();
          }
        });
      } else {
        updateContactMethodRecord(values).then((data) => {
          if (data.err) {
              setValues({ ...values, submitting: false });
            NotifyMessage({
              message: data.err,
              type: "error",
            });
          } else {
            NotifyMessage({
              message: data.message,
              type: "success",
            });
            getAllContactmethods();
            setOpenPopup(false);
            setValues({ ...initialFValues });
            resetForm();
          }
        });
      }
    }
  };
  return (
    <div>
      <Form>
        {field.name == "social_media" && (
          <div className="flex m-3 ">
            <Tooltip title="email">
              <div className="social__media__logo" onClick={handleLogoClick}>
                <i className="bx-sm bx bx-mail-send"></i>
              </div>
            </Tooltip>
            <Tooltip title="youtube">
              <div className="social__media__logo" onClick={handleLogoClick}>
                <i className="bx-sm bx bxl-youtube"></i>
              </div>
            </Tooltip>{" "}
            <Tooltip title="facebook">
              <div className="social__media__logo" onClick={handleLogoClick}>
                <i className="bx-sm  bx bxl-facebook"></i>
              </div>
            </Tooltip>{" "}
            <Tooltip title="instagram">
              <div className="social__media__logo" onClick={handleLogoClick}>
                <i className="bx-sm  bx bxl-instagram-alt"></i>
              </div>
            </Tooltip>{" "}
            <Tooltip title="telegram">
              <div className="social__media__logo" onClick={handleLogoClick}>
                <i className="bx-sm bx bxl-telegram"></i>
              </div>
            </Tooltip>{" "}
            <Tooltip title="linkedin">
              <div className="social__media__logo" onClick={handleLogoClick}>
                <i className="bx-sm bx bxl-linkedin"></i>
              </div>
            </Tooltip>{" "}
            <Tooltip title="pinterest">
              <div className="social__media__logo" onClick={handleLogoClick}>
                <i className="bx-sm bx bxl-pinterest"></i>
              </div>
            </Tooltip>{" "}
            <Tooltip title="twitter">
              <div className="social__media__logo" onClick={handleLogoClick}>
                <i className="bx-sm bx bxl-twitter"></i>
              </div>
            </Tooltip>
          </div>
        )}
        <Grid container>
          <Grid item xs={8}>
            <Controls.Input
              name={field.name}
              label={field.label}
              value={values[field.name]}
              onChange={handleInputChange}
              error={errors[field.name]}
            />
          </Grid>

          <Grid item xs={12}>
            <div>
              <Controls.Button
                disabled={values.submitting ? true : false}
                onClick={handleSubmit}
                text={
                  values.editing == true
                    ? values.submitting
                      ? "Editing..."
                      : "Edit"
                    : values.submitting
                    ? "Adding..."
                    : "Add"
                }
                variant="contained"
                className="Button"
              
              />
              <Controls.Button
                onClick={() => {
                  setField({});
                }}
                text="Cancle"
                color="default"
              />
            </div>
          </Grid>
        </Grid>
      </Form>
    </div>
  );
};

export default AddNewContactForm;
