import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import Controls from "../controls/Controls";
import { Form, useForm } from "../customHelpers/useForm";
import Service from "../../actions/Service";
const initialFValues = {
  name: "",
  editing: false,
};
const ServiceForm = (props) => {
  const {
    recordForEdit,
    NotifyMessage,
    Id,
    getAllServices,
    setOpenPopup,
    setRecordForEdit,
  } = props;
  const { addService, updateService } = Service();
  useEffect(() => {
    if (recordForEdit != null)
      setValues({
        ...recordForEdit,
      });
  }, [recordForEdit]);
  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("name" in fieldValues)
      temp.name = fieldValues.name.length != 0 ? "" : "This field is required.";

    setErrors({
      ...temp,
    });
    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setValues({ ...values, submitting: true });
      if (values.editing == false) {
        addService(values, Id).then((data) => {
          if (data.err) {
            setValues({ ...values, submitting: false });
            NotifyMessage({
              message: data.err,
              type: "error",
            });
          } else {
            resetForm();
            getAllServices();
          }
        });
      } else {
        updateService(values).then((data) => {
          if (data.err) {
            setValues({ ...values, submitting: false });
            NotifyMessage({
              message: data.err,
              type: "error",
            });
          } else {
            NotifyMessage({
              message: data.message,
              type: "success",
            });
            setValues(initialFValues);
            resetForm();
            getAllServices();
            setOpenPopup(false);setRecordForEdit(null)
          }
        });
      }
    }
  };
  return (
    <Form>
      <Grid container>
        <Grid item xs={12}>
          <Controls.Input
            name="name"
            label="Name"
            value={values.name}
            onChange={handleInputChange}
            error={errors.name}
          />
        </Grid>

        <Grid item xs={12}>
          <div>
            <Controls.Button
              onClick={handleSubmit}
              disabled={values.submitting ? true : false}
              text={
                values.editing == true
                  ? values.submitting
                    ? "Editing..."
                    : "Edit"
                  : values.submitting
                  ? "Adding..."
                  : "Add"
              }
              variant="contained"
              className="Button"
              type="submit"
            />
          </div>
        </Grid>
      </Grid>
    </Form>
  );
};

export default ServiceForm;
