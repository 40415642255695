import { Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import Catagory from "../actions/Catagory";
import CallCenter from "./auth/CallCenter";
import Controls from "./controls/Controls";
import Notify from "./customHelpers/Notify";
import Layout from "./layout/Layout";
import StatusCard from "./status-card/StatusCard";
const SubCatagoryComponent = (props) => {
  const { NotifyMessage, notify, setNotify } = Notify();
  const { companiesList } = Catagory();
  const [values, setValues] = useState({
    loading: false,
    catagories: [],
  });
  const { loading, catagories } = values;
  const Id = props.match.params.catagoryId;
  useEffect(() => {
    getAllCompanies();
  }, [Id]);
  const getAllCompanies = () => {
    setValues({ catagories: [], loading: true });

    companiesList(Id).then((data) => {
      if (data.err) {
        NotifyMessage({
          message: data.err,
          type: "error",
        });
      } else {
        setValues({
          catagories: data.result ? data.result : [],
          loading: false,
        });
      }
    });
  };
  return (
    <Layout>
      <CallCenter>
        <div className="card">
          <Typography variant="h6" className="page-header">
            Catagories
          </Typography>
          <div className="row">
            {catagories.children?.map((item, index) => (
              <div className="col-4" key={index}>
                <StatusCard item={item} />
              </div>
            ))}
          </div>
        </div>
      </CallCenter>
    </Layout>
  );
};

export default withRouter(SubCatagoryComponent);
