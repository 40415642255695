import React from "react";
import { Button, makeStyles } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 0,
    margin: theme.spacing(0.5),

  },
  secondary: {
    backgroundColor: theme.palette.secondary.main,
    "& .MuiButton-label": {
      color: theme.palette.secondary.contrastText,
    },
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      "& .MuiButton-label": {
        color: theme.palette.secondary.contrastText,
      },
    },
  },
  primary: {
    backgroundColor: theme.palette.primary.main,
    "& .MuiButton-label": {
      color: theme.palette.primary.contrastText,
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiButton-label": {
        color: theme.palette.primary.contrastText,
      },
    },
  },
}));

export default function ActionButton(props) {
  const { color, children, onClick, title = "add" } = props;

  const classes = useStyles();

  return (
    <Tooltip title={title} aria-label="add">
      <Button className={`${classes.root} ${classes[color]}`} onClick={onClick}>
        {children}
      </Button>
    </Tooltip>
  );
}
