import fetch from "isomorphic-fetch";
import { useHistory } from "react-router-dom";

import { API } from "../config";
import { getCookie, userSessionExpired } from "./auth";
const News = () => {
  const history = useHistory();
  const addNews = (news) => {
    return fetch(`${API}/add-news`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getCookie("token")}`,
      },
      body: news,
    })
      .then((response) => {
        userSessionExpired(response, history);
        return response.json();
      })
      .catch((err) => err);
  };
  const getNews = () => {
    return fetch(`${API}/get-news`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("token")}`,
      },
    })
      .then((response) => {
        userSessionExpired(response, history);
        return response.json();
      })
      .catch((err) => err);
  };
  const getUserNews = () => {
    return fetch(`${API}/get-user-news`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("token")}`,
      },
    })
      .then((response) => {
        userSessionExpired(response, history);
        return response.json();
      })
      .catch((err) => err);
  };
  const deleteNews = (Id) => {
    return fetch(`${API}/delete-news/${Id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("token")}`,
      },
    })
      .then((response) => {
        userSessionExpired(response, history);
        return response.json();
      })
      .catch((err) => err);
  };
  const updateNews = (news, Id) => {
    return fetch(`${API}/update-news/${Id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getCookie("token")}`,
      },
      body: news,
    })
      .then((response) => {
        userSessionExpired(response, history);
        return response.json();
      })
      .catch((err) => err);
  };
  const adminApproveNews = (Id) => {
    return fetch(`${API}/admin-approve-news/${Id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getCookie("token")}`,
      },
    })
      .then((response) => {
        userSessionExpired(response, history);
        return response.json();
      })
      .catch((err) => err);
  }; 

  return {
    addNews,
    getNews,
    deleteNews,
    updateNews,
    getUserNews,
    adminApproveNews,
  };
};

export default News;
